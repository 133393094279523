import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, useHistory } from "react-router-dom";
import * as api from "../../api";
import { saveAssociatedAssests, saveCustomerphoneNumber, saveTenancySummaryList, saveCustomerTenacies, saveCustomerDetails, selectedTenacyType, workOrderRepairObject, getReferenceNumber,getHeatingHotWaterCase,
  getMaintResp,
  getRepairResp,
  getContractorName,
  getDeterminedContractorDetails,
  getContractorPayload, } from "../../ducks/redux/actions/getAddress";
import { customersData, isActiveTenancy, userChosenPropertyAddress, userChosenPropertyId, getAssetList, defaultAssetId, premisesTypeList, userChosenAccountNumber, userChosentenancyGroup, userChosenAccountData, userChosenCustomerAccountData, userChosenBackValue, customerPreferenceData, userChosenAlertValue, userChosenIsRecall, loadDuplicateRepairPage, userChosenResumeRepair, userChosenDuplicateEntity, userChosenCurrentValue, backToCaseAsset, caseAssetAddress, userChosentenancyCount, userChosenReporterId, caseCreatedBy, userChosenAssociatedAssetsList, customerAdditionalAlertData, clearMediaFiles, userChosenUploadedMediaIdentifiers, assetDataStatus, selectedTenancyGroup } from "../../ducks/redux/actions/userChoices";
import CustomerResponseDetails from "../../models/Customer/CustomerResponseDetails";
import { PremisesType } from "../../models/Customer/Premises";
import Tenancies from "../../models/Customer/Tenancies";
import TenancySummary from "../../models/Customer/TenancySummary";
import { AssocitaedAssetsType, RelatedPremisesType } from "../../models/Property/AdminUnits";
import LoadingSpinner from "../../storybook/LoadingSpinner";
import AssetCard from "../AssetCard";
import CustomerCard from "../CustomerCard";
import RentCard from "../RentCard";
import WorkCard from "../WorkCard";
import AccountCard from "../AccountCard";
import { PropertySelect } from "./components";
import { BalanceType } from "../../models/Accounts/BalanceType";
import KeyContact from "../KeyContact";
import UCCTenancyDetails from "../UCCTenancyDetails";
import UCCRentDetails from "../UCCRentDetails";
import { PermissionsList } from "../../models/Permissions";
import { trackEvent } from "../../appInsights/TelemetryService";
import { AlertValue, BackValue, SearchType, CustomerOrProperty } from "../../models/shared/Enum";
import CautionAlert from "../CautionAlert"
import PropertyAlert from "../PropertyAlert";
import {
  userChosenUPRN,
  userChosenCovidQuestion,
  userChosenContactDetails,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  userChosenAdditionalDescription,
  userChosenVandalismQuestion,
  userChosenVandalismAdditionalInformation,
  userChosenVulnerabilityQuestion,
  userChosenVulnerabilityAdditionalInformation,
  userChosenDuplicateQuestion,
  userChosenEmergencyQuestion,
  userChosenRepairLocationId,
  userChosenHighLevelDescription,
  userChosenRepairLocation,
  userChosenSupplementaryQuestions,
  userChosenSOR,
  userChosenSTATUS,
  userChosenEligibilityErrorCode,
  userChosenIsReporter,
  assetDataResponse,
  assetsByIDData,
  checkForLandlord, isCatalystProperty, userChosenIsSurvey
} from "../../ducks/redux/actions/userChoices";
import { updateDashboardForm, userTypedRepairReasons, assetByIdDataObject } from "../../ducks/redux/actions/dashboardReducer.actions";
import { AccountTypeList } from "../../models/Accounts/AccountType";
import { CASE_ASSET_CHECK, CASE_CUSTOMER_NAME, CASE_SELECTED_ANSWERS, CASE_TENANCY_ID, CLEAR_CASE_STATE, CONTACT_INFORMATION_VALUE, EDIT_BUTTON_CLICK } from "../../ducks/redux/actionTypes";
import CaseCard from "../Cases/CaseCard";
import Preference from "../../models/Customer/Preference";
import AdditionalNeedAlert from "../AdditionalNeedAlert";
import { isUndefinedNullOrEmpty } from "../../utils/translation/helper";
import { getDeepLink } from '../../utils/formatting';
import openNewWindow from "../../assets/icons/Open-new-window-icon.png";

interface Customer360ViewProps extends RouteComponentProps<any> { }
const Customer360View: React.FC<Customer360ViewProps> = (props) => {
  const isCheckForLandlord = process.env.REACT_APP_ISCHECKFORLANDLORD;
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history =  useHistory();
  const customerId = useSelector(
    (state: any) => state?.userChoicesReducer?.custommerID
  );
  const isCaseCheck = useSelector(
    (state: any) => state?.userChoicesReducer?.isCaseAllowed
  );
  const isP360Maintainence = useSelector(
    (state: any) => state.userChoicesReducer?.isP360RepairsMaintainenceValue
  );
  const [dropDown, setDropDown] = useState("none");
  const [defaultAssociatedAsset, setDefaultAssociatedAsset] = useState("");
  const arrayArea: Array<{ key: string; value: string }> = [];
  const [customerData, setCustomerData] = useState<CustomerResponseDetails>();
  const [associatedAssetList, setAssociatedAssetsList] = useState(arrayArea);
  const [inAssociatedAssetList, setInAssociatedAssetsList] = useState(arrayArea);
  const tenancies: Tenancies[] = [];
  const [tenanciesData, setTenanciesData] = useState(tenancies);
  const [Area, setArea] = useState(false);
  const [isCustomerCard, setIsCustomerCard] = useState(false);
  const [isWorkCard, setIsWorkCard] = useState(false);
  const [isAssetCard, setIsAssetCard] = useState(false);
  const [isAccountCard, setIsAccountCard] = useState(false);
  const [showRent, setShowRent] = useState(false);
  const [showAccount, setShowAccount] = useState(true);
  const [isRentCard, setIsRentCard] = useState(false);
  const [isKeyContact, setIsKeyContact] = useState(false);
  const [isRepair, setIsRepair] = useState(false);
  const [isCautionBanner, setIsCaution] = useState(false);
  const [activeTenancy, setActiveTenancy] = useState(false);
  const [preferenceCheck, setPreferenceCheck] = useState(false);
  const [isPropertyBanner, setIsProperty] = useState(false);
  const [showVantage, setShowVantage] = useState(false);
  const [propertyBannerCheck, setPropertyBannerCheck] = useState(false);
  const [isUCCCard, setIsUCCCard] = useState(false);
  const [userAsset, setUserAsset] = useState<any>();
  const getPermissionsData = useSelector((state: any) => state?.userChoicesReducer?.userProfilePermissions);
  const customerDataVal = useSelector((state: any) => state?.userChoicesReducer?.customersData);
  const [landlordValues, setLandlordValues] = useState([""]);
  const [identifiedCatalystProperty, setIdentifiedCatalystProperty] = useState(false);
  const [catalystBannerMessageA, setCatalystBannerMessageA] = useState("");
  const [catalystBannerMessageB, setCatalystBannerMessageB] = useState("");
  const [isCaseCard, setIsCaseCard] = useState(false);
  const [additionalAlert, setAdditionalAlert] = useState(false);
  let assetRegionValue: string = "";
  const premisesList: RelatedPremisesType[] = [];

  enum LandlordAppConfigValues {
    IsCheckForLandlord = "IsCheckForLandlordP360",
    LandlordValues = "LandlordValues",
    CatalystRepairDisabledAreas = "CatalystRepairDisabledAreasP360",
    CatalystRegistrationAreas = "CatalystRegistrationAreas",
    isCataLystCaseCard= "isCataLystCaseCard"
  };

  let newAssociatedAssets: any[] = [];
  let oldAssociatedAssets: any[] = [];
  let associatedAssetsCount: number = 0;
  const finishJourney = useSelector((state:any) => state?.financialReferralReducer?.isJourneyFinish);
  const clear = () => {
    dispatch(userChosenIsSurvey(false));
    dispatch(userChosentenancyCount(0))
    dispatch(backToCaseAsset(false))
    dispatch(userChosenPropertyAddress(""));
    dispatch(getReferenceNumber(null))
    dispatch(userChosenRepairLocationId(0));
    dispatch(userChosenRepairLocation(""));
    dispatch(userTypedRepairReasons(""));
    dispatch(userChosenHighLevelDescription(""));
    dispatch(userChosenSupplementaryQuestions(""));
    dispatch(userChosenEmergencyQuestion(""));
    dispatch(userChosenPropertyId(""));
    dispatch(userChosenDuplicateQuestion(""));
    dispatch(userChosenDuplicateEntity(""));
    dispatch(userChosenAdditionalDescription(""));
    dispatch(userChosenVandalismQuestion("No"));
    dispatch(userChosenVandalismAdditionalInformation(""));
    dispatch(userChosenCovidQuestion(""));
    dispatch(userChosenVulnerabilityQuestion(""));
    dispatch(userChosenVulnerabilityAdditionalInformation(""));
    dispatch(userChosenContactDetails(""));
    dispatch(userChosenUPRN(""));
    dispatch(userChosenSOR(""));
    dispatch(getRepairResp(""));
    dispatch(getHeatingHotWaterCase(""));
    dispatch(getMaintResp(""));
    dispatch(getDeterminedContractorDetails(""));
    dispatch(getContractorName(""));
    dispatch(getContractorPayload(""));
    dispatch(userChosenSTATUS(""));
    dispatch(userChosenEligibilityErrorCode(""));
    dispatch(userChosenContactPhone(""));
    dispatch(userChosenContactName(""));
    dispatch(userChosenRelationDetails(""));
    dispatch(selectedTenacyType(""));
    dispatch(workOrderRepairObject(null));
    dispatch(userChosenIsReporter(false));
    dispatch({ type: CLEAR_CASE_STATE });
    dispatch({ type: CASE_SELECTED_ANSWERS });
    dispatch(saveCustomerphoneNumber(''));
    dispatch(caseAssetAddress(""))
    dispatch(userChosenRelationDetails(''))
    dispatch(userChosenReporterId(''))
    dispatch({ type: EDIT_BUTTON_CLICK, payload: false })
    dispatch(loadDuplicateRepairPage(false));
    dispatch(userChosenResumeRepair(false));
    dispatch(caseCreatedBy(""));
    dispatch({ type: CASE_CUSTOMER_NAME, payload: '' })
    dispatch({ type: CASE_ASSET_CHECK, payload: false })
    dispatch({ type: CONTACT_INFORMATION_VALUE })
    dispatch({ type: CASE_TENANCY_ID })
    dispatch(customerPreferenceData({}))
    dispatch(customerAdditionalAlertData({}))
    dispatch(clearMediaFiles([]));
    dispatch(userChosenUploadedMediaIdentifiers([]));
    dispatch(assetByIdDataObject(null));
    dispatch(selectedTenancyGroup(''))
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
      try {
        dispatch(userChosenAssociatedAssetsList(null));
        dispatch(saveAssociatedAssests(null));
        trackEvent("Customer360View");        
        clear();
        dispatch(userChosenIsRecall(false))
        dispatch(userChosenBackValue(BackValue.Customer360View));
        dispatch(userChosenCurrentValue(BackValue.Customer360View));
        dispatch(caseCreatedBy(SearchType.Customer));
        setIsLoading(true);


        const showCustomer = getPermissionsData?.indexOf(PermissionsList.view_customer360_customer_details_card);
        if (showCustomer != -1) {
          setIsCustomerCard(true)
        }
        else {
          setIsCustomerCard(false)
        }
        const showWork = getPermissionsData?.indexOf(PermissionsList.view_customer360_wo_summary_card);
        if (showWork != -1) {
          setIsWorkCard(true)
        }
        else {
          setIsWorkCard(false)
        }
        const showAsset = getPermissionsData?.indexOf(PermissionsList.view_customer360_asset_summary_card);
        if (showAsset != -1) {
          setIsAssetCard(true)
        }
        else {
          setIsAssetCard(false)
        }

        const showAccount = getPermissionsData?.indexOf(PermissionsList.view_customer360_multi_account_summary_card);
        if (showAccount != -1) {

          setIsAccountCard(true)
        }
        else {
          setIsAccountCard(false)
        }
        const showRentCard = getPermissionsData?.indexOf(PermissionsList.view_customer360_rent_account_only_summary_card);
        if (showRentCard != -1) {
          setIsRentCard(true)
        }
        else {
          setIsRentCard(false)
        }
        const showKeyContact = getPermissionsData?.indexOf(PermissionsList.view_customer360_key_contacts_summary_card);
        if (showKeyContact != -1) {
          setIsKeyContact(true)
        }
        else {
          setIsKeyContact(false)
        }
        const showRepair = getPermissionsData?.indexOf(PermissionsList.create_customer360_customer_raise_a_repair);
        if (showRepair != -1) {
          setIsRepair(true)
        }
        else {
          setIsRepair(false)
        }
        const showCaution = getPermissionsData?.indexOf(PermissionsList.view_customer360_caution_alerts_banner);
        if (showCaution != -1) {
          setIsCaution(true)
        }
        else {
          setIsCaution(false)
        }
        const showProperty = getPermissionsData?.indexOf(
          PermissionsList.view_asset360_property_alerts_banner
        );
        setIsProperty(showProperty != -1);
        const showOpenVantage = getPermissionsData?.indexOf(PermissionsList.view_open_crm);
        setShowVantage(showOpenVantage !=-1)

        const customerResponse = await api.getCustomer(customerId);
        const tenancyResponse = await api.getTenancies(customerId);

        if (customerResponse?.preferences?.length > 0) {
          let customerAdditionalAlertDataObj: Preference[] = [];
          let customerPreferenceAlertObj: Preference[] = [];
          customerAdditionalAlertDataObj = customerResponse?.preferences?.filter((additionalNeedAlert: any) => additionalNeedAlert.preference.type == 'additionalNeeds');
          customerPreferenceAlertObj = customerResponse?.preferences?.filter((additionalNeedAlert: any) => additionalNeedAlert.preference.type != 'additionalNeeds');
          if (customerPreferenceAlertObj.length > 0) {
            setPreferenceCheck(true)
            dispatch(customerPreferenceData(customerPreferenceAlertObj))
          }
          if (customerAdditionalAlertDataObj.length > 0) {
            setAdditionalAlert(true);
            dispatch(customerAdditionalAlertData(customerAdditionalAlertDataObj))
          }
        }

        setTenanciesData(tenancyResponse)
        dispatch(
          saveCustomerphoneNumber(customerResponse?.addresses?.telecomsAddresses)
        );
        dispatch(saveCustomerDetails(customerResponse));
        dispatch(customersData(customerResponse));
        getActiveAssociatedAsset(customerResponse, tenancyResponse);
        setCustomerData(customerResponse);
      }
      catch (e) {
        setIsLoading(false)
        console.error(e);
        const timerId = setTimeout(() => {
          props.history.push("/GenericError");
        }, 1000);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const assetProperty = userAsset && userAsset?.filter((eachAsset: any) => eachAsset.assetType === 'Property');
        const tenancySum = userAsset && customerDataVal?.tenanciesSummaries && customerDataVal?.tenanciesSummaries?.filter((eachSummary: any) => eachSummary.tenancySummary.uprn === assetProperty[0].uprn && eachSummary.tenancySummary.statuses[0].value === true);
        const response = await api.getUCCReferenceData();
        let arrayTenancyType = Object.values(response[0].namespace.keyValues);
        const filteredArrayTenancyType: any = arrayTenancyType && arrayTenancyType?.filter((el: any) => el.tenancyTypeID === tenancySum[0]?.tenancySummary?.tenancyType)
        const isDisplayUC = filteredArrayTenancyType && filteredArrayTenancyType[0]?.displayUC;
        const showUCCCard = getPermissionsData?.indexOf(PermissionsList.view_customer360_customer_UCC_Card);
        if (showUCCCard != -1 && isDisplayUC) {
          setIsUCCCard(true);
        }
        else {
          setIsUCCCard(false);
        }
      } catch (error) { }
    })()
  }, [userAsset, customerDataVal]);


  const checkforLandlordValues = async (assetId: string, currentAssetRegion: string) => {
    const appCatalystRegistrationAreasValues = await api.GetAppConfigToggleValue(
      LandlordAppConfigValues.CatalystRegistrationAreas
    );
    const appCatalystRepairDisabledAreasValues = await api.GetAppConfigToggleValue(
      LandlordAppConfigValues.CatalystRepairDisabledAreas
    );

    const isCatalystRegistrationAreas = !isUndefinedNullOrEmpty(currentAssetRegion) && appCatalystRegistrationAreasValues.value.toLowerCase().includes(currentAssetRegion.toLowerCase())
    const isCatalystDisabledArea = !isUndefinedNullOrEmpty(currentAssetRegion) && appCatalystRepairDisabledAreasValues.value.toLowerCase().includes(currentAssetRegion.toLowerCase());
    let isEnabledCatalystProperty = isCatalystRegistrationAreas && !isCatalystDisabledArea

    if (isCheckForLandlord === "true") {
      const isCataLystCaseCardValue = await api.GetAppConfigToggleValue(
        LandlordAppConfigValues.isCataLystCaseCard
      );
      const appConfigIsCheckForLandlord = await api.GetAppConfigToggleValue(
        LandlordAppConfigValues.IsCheckForLandlord
      );

      if (appConfigIsCheckForLandlord?.value == "true") {
        const appConfigLandlordValues = await api.GetAppConfigToggleValue(
          LandlordAppConfigValues.LandlordValues
        );

        let appconfigLandlordValues = appConfigLandlordValues?.value;
        let landlordsList = appconfigLandlordValues.split(",");
        setLandlordValues(landlordsList);

        const associatesdata = await api.GetPropertyAssociates(assetId);

        let currentLandlord = associatesdata?.associates?.landLord?.organisation?.name;
        let previousLandLord = associatesdata?.associates?.previousLandLord?.organisation?.name;

        const landlordValuesExist = currentLandlord?.length > 0 || previousLandLord?.length > 0
        if (landlordValuesExist) {
          const isCatalystLandlord = 
          currentLandlord?.length > 0 && appconfigLandlordValues.includes(currentLandlord) || 
          previousLandLord?.length > 0 && appconfigLandlordValues.includes(previousLandLord)
          const identifiedCatalystProperty = isCatalystRegistrationAreas || isCatalystLandlord
          isEnabledCatalystProperty = isCatalystLandlord && isEnabledCatalystProperty
          dispatch(isCatalystProperty(identifiedCatalystProperty))
          setIsWorkCard(!isCatalystRegistrationAreas || isEnabledCatalystProperty);          
          dispatch(checkForLandlord(identifiedCatalystProperty));
          setIdentifiedCatalystProperty(isCatalystRegistrationAreas);
          if(isCataLystCaseCardValue.value === "true"){
            setIsCaseCard(false);
          }
          else{
            setIsCaseCard(true);
          }
        }
        else {
          dispatch(isCatalystProperty(isCatalystRegistrationAreas))
        }
      }
      else {
        dispatch(isCatalystProperty(isCatalystRegistrationAreas))

      }
    } else {
      dispatch(isCatalystProperty(isCatalystRegistrationAreas))
    }

    switch (assetRegionValue) {
      case 'HUB1-REP':
        setCatalystBannerMessageA(t("CP126EnabledHub1a"));
        setCatalystBannerMessageB(t("CP126EnabledHubb"));
        break;
      case 'HUB2-REP':
        setCatalystBannerMessageA(t("CP126EnabledHub2a"));
        setCatalystBannerMessageB(t("CP126EnabledHubb"));
        break;
      case 'HUB3-REP':
        setCatalystBannerMessageA(t("CP126EnabledHub3a"));
        setCatalystBannerMessageB(t("CP126EnabledHubb"));
        break;
      case 'HUB4-REP':
        setCatalystBannerMessageA(t("CP126EnabledHub4a"));
        setCatalystBannerMessageB(t("CP126EnabledHubb"));
        break;
      default:
        // For Non Catalyst Properties, isIdentifiedCatalystProperty will be false, message wont be visible
        // For Catalyst properties where landlord is catalyst but fron a diff asset region
        setCatalystBannerMessageA(t("CP125a"));
        setCatalystBannerMessageB(t("CP125b"));
        break;
    }

    if(isCatalystDisabledArea) {
      setCatalystBannerMessageA(t("CP125a"));
      setCatalystBannerMessageB(t("CP125b"));
    }
    
  }

  const getAssociatedAsset = (key: any) => {
    (async () => {
      try {
        const assetsByID = await api.getAssetsByid(key);
        dispatch(assetByIdDataObject(assetsByID));
        dispatch(assetsByIDData(assetsByID));
        dispatch(assetDataStatus(assetsByID.assetStatus));
        assetRegionValue = assetsByID?.region;

        
        let associtaedAssets: any[] = [];
        const associatedAssetsResponse = await api.getAssociatedAssets(key);
        associtaedAssets.push(associatedAssetsResponse)
        dispatch(saveAssociatedAssests(associtaedAssets));
        dispatch(assetDataResponse(associatedAssetsResponse));
        setUserAsset(associatedAssetsResponse);
        associatedAssetsResponse.forEach((element) => {
          const premisesEntry: RelatedPremisesType = new RelatedPremisesType();
          premisesEntry.premisesType = element.assetType as PremisesType;
          premisesEntry.premisesTypeId = element.id;
          premisesList.push(premisesEntry);
        });
        dispatch(premisesTypeList(premisesList))
        
        checkforLandlordValues(key, assetRegionValue);
      }
      catch (e) {
        setIsLoading(false)
        console.error(e);
        const timerId = setTimeout(() => {
          props.history.push("/GenericError");
        }, 1000);
      }
    })();
  }

  const optionsFromPremises = (associatedAssetList: any) =>
    associatedAssetList.map((e: any, key: any) => ({
      key: e.value,
      value: e.key,
    }))
  const getActiveAssociatedAsset = (customerResponse: CustomerResponseDetails, tenancyResponse: Tenancies[]) => {
    (async () => {
      try {

        const activeTenanciesSummaries =
          customerResponse?.tenanciesSummaries.filter(
            (ts) =>
              ts.tenancySummary.statuses.find(
                (s) => s.type === "isTenancyActive"
              )?.value === true
          );
          if (activeTenanciesSummaries.length > 0) {
            dispatch({ type: CASE_TENANCY_ID, payload: activeTenanciesSummaries[0]?.tenancySummary?.tenancyId })
          }
          
        dispatch(saveCustomerTenacies(activeTenanciesSummaries));
        if (activeTenanciesSummaries.length > 0) {
          const tenancySummaryList: TenancySummary[] = [];
          if (tenancyResponse.length > 1) {
            tenancyResponse.map((ts: any) => {
              let tenancySummaryEntry: TenancySummary[] =
                activeTenanciesSummaries.filter(
                  (x) => x.tenancySummary.tenancyId === ts.tenancyIdentifier
                );

              if (tenancySummaryEntry.length > 0) {
                if (
                  ts.tenancyGroup != "Non-residential" &&
                  ts.tenancyGroup != "Commercial"
                ) {
                  tenancySummaryList.unshift(tenancySummaryEntry[0]);
                } else {
                  tenancySummaryList.push(tenancySummaryEntry[0]);
                }
              }
            });
          } else {
            tenancySummaryList.push(activeTenanciesSummaries[0]);
          }
          dispatch(saveTenancySummaryList(tenancySummaryList));
          dispatch({ type: CASE_TENANCY_ID, payload: tenancySummaryList[0]?.tenancySummary?.tenancyId })
          const tenancyType =
            tenancySummaryList[0]?.tenancySummary?.tenancyType;
          const activeTenanciesPremisesIds: Tenancies[] = tenancySummaryList
            .map((ts: any) => ts?.tenancySummary?.premisesIds)
            .flat();

          const promises = activeTenanciesPremisesIds.map(async (item: any) => {
            return await api.getAssociatedAssets(item);
          });

          Promise.all(promises).then((results) => {
            setUserAsset(results[0]);
            activeTenanciesPremisesIds?.map(async (assetId: any) => {
              results.forEach((element) => {
                const resultF = element.filter(
                  (addressData) => addressData.id === assetId
                );

                if (resultF !== null) newAssociatedAssets.push(resultF);
              });
            });
            const associatedAssetsList: AssocitaedAssetsType[] = [];
            newAssociatedAssets?.map((addressData: any) => {
              addressData?.map((address: any) => {
                const associatedAssetsTypeEntry: AssocitaedAssetsType =
                  new AssocitaedAssetsType();

                associatedAssetsTypeEntry.key = address?.id;
                associatedAssetsTypeEntry.value =
                  address?.addresses?.postalAddresses[0]?.formattedAddress;
                associatedAssetsList.push(associatedAssetsTypeEntry);
              });
            });

            setDefaultAssociatedAsset(associatedAssetsList[0]?.key);
          
            getAssociatedAsset(associatedAssetsList[0]?.key);
            callAlertApi(associatedAssetsList[0]?.key);
            setAssociatedAssetsList(associatedAssetsList);
            dispatch(userChosenAssociatedAssetsList(associatedAssetsList));
            associatedAssetsCount = associatedAssetsCount + associatedAssetsList.length;
            dispatch(isActiveTenancy(true));
            setActiveTenancy(true);
            dispatch(defaultAssetId(associatedAssetsList[0]?.key))
            dispatch(
              updateDashboardForm({
                assetId: associatedAssetsList[0]?.key
              })
            );
            dispatch(userChosenPropertyAddress(associatedAssetsList[0]?.value));
            let TenancySummaryEntry = activeTenanciesSummaries.find(
              (st) => st.tenancySummary.premisesIds[0] === associatedAssetsList[0]?.key
            );
            if (TenancySummaryEntry != undefined && TenancySummaryEntry != null) {
              let tenancyResponseEntry = tenancyResponse.find(x => x.tenancyIdentifier === TenancySummaryEntry?.tenancySummary?.tenancyId)
              if (tenancyResponseEntry != undefined && tenancyResponseEntry != null) {
                let tenancyGroup =
                  tenancyResponseEntry?.tenancyGroup;
                if (tenancyGroup != undefined && tenancyGroup != null) {
                  dispatch(userChosentenancyGroup(tenancyGroup));
                }
              }
              let paymentReference =
                TenancySummaryEntry?.tenancySummary?.paymentReference;
              if (paymentReference != undefined && paymentReference != null) {
                (async () => {
                  try {
                    const accountBalance = await api.getAccount(paymentReference);
                    getCustomeAccountData(true, paymentReference)

                    dispatch(
                      userChosenAccountData(accountBalance)
                    );
                  }
                  catch (e) {
                    getCustomeAccountData(false, null)
                    dispatch(
                      userChosenAccountData(null)
                    );
                    setIsLoading(false)
                  }
                })();
                dispatch(userChosenAccountNumber(paymentReference));
              }
              else {
                setIsLoading(false)
              }
            }
            if (associatedAssetsCount > 1) {
              setArea(true);
            } else {
              setArea(false);
            }
          }).then(() => getActiveInAssociatedAsset(customerResponse, tenancyResponse))
        }
        else {
          getActiveInAssociatedAsset(customerResponse, tenancyResponse)
        }

      }
      catch (e) {
        setIsLoading(false)
        console.error(e);
        const timerId = setTimeout(() => {
          props.history.push("/GenericError");
        }, 1000);
      }
    })()
  }
  const getActiveInAssociatedAsset = (customerResponse: CustomerResponseDetails, tenancyResponse: Tenancies[]) => {
    (async () => {
      try {
        const inActiveTenanciesSummaries =
          customerResponse?.tenanciesSummaries.filter(
            (ts) =>
              ts.tenancySummary.statuses.find(
                (s) => s.type === "isTenancyActive"
              )?.value === false
          );
        if (inActiveTenanciesSummaries.length > 0) {
          const tenancySummaryList: TenancySummary[] = [];
          if (tenancyResponse.length > 1) {
            tenancyResponse.map((ts: any) => {
              let tenancySummaryEntry: TenancySummary[] =
                inActiveTenanciesSummaries.filter(
                  (x) => x.tenancySummary.tenancyId === ts.tenancyIdentifier
                );

              if (tenancySummaryEntry.length > 0) {
                if (
                  ts.tenancyGroup != "Non-residential" &&
                  ts.tenancyGroup != "Commercial"
                ) {
                  tenancySummaryList.unshift(tenancySummaryEntry[0]);
                } else {
                  tenancySummaryList.push(tenancySummaryEntry[0]);
                }
              }
            });
          } else {
            tenancySummaryList.push(inActiveTenanciesSummaries[0]);
          }
          dispatch(saveTenancySummaryList(tenancySummaryList));
          const tenancyType =
            tenancySummaryList[0]?.tenancySummary?.tenancyType;
          const activeTenanciesPremisesIds: Tenancies[] = tenancySummaryList
            .map((ts: any) => ts?.tenancySummary?.premisesIds)
            .flat();

          const promises = activeTenanciesPremisesIds?.map(async (item: any) => {
            return await api.getAssociatedAssets(item);
          });

          Promise.all(promises).then((results) => {
            activeTenanciesPremisesIds?.map(async (assetId: any) => {
              results?.forEach((element) => {
                const resultF = element?.filter(
                  (addressData) => addressData?.id === assetId
                );

                if (resultF !== null) oldAssociatedAssets.push(resultF);
              });
            });

            const associatedAssetsList: AssocitaedAssetsType[] = [];
            oldAssociatedAssets?.map((addressData: any) => {
              addressData?.map((address: any) => {
                const associatedAssetsTypeEntry: AssocitaedAssetsType =
                  new AssocitaedAssetsType();

                associatedAssetsTypeEntry.key = address?.id;
                associatedAssetsTypeEntry.value =
                  address?.addresses?.postalAddresses[0]?.formattedAddress;
                associatedAssetsList.push(associatedAssetsTypeEntry);
              });
            });
            setInAssociatedAssetsList(associatedAssetsList);
            //Former Tenancy Case
            if (associatedAssetsCount < 1) {
              setDefaultAssociatedAsset(associatedAssetsList[0]?.key);
              getAssociatedAsset(associatedAssetsList[0]?.key)
           
              dispatch(defaultAssetId(associatedAssetsList[0]?.key))
              dispatch(userChosenPropertyAddress(associatedAssetsList[0]?.value));
              dispatch(
                updateDashboardForm({
                  assetId: associatedAssetsList[0]?.key
                })
              );
              dispatch(isActiveTenancy(false));
              setActiveTenancy(false);
              let TenancySummaryEntry = inActiveTenanciesSummaries.find(
                (st) => st.tenancySummary.premisesIds[0] === associatedAssetsList[0]?.key
              );
              if (TenancySummaryEntry != undefined && TenancySummaryEntry != null) {

                // Dispatch Tenency Id to retrieve cases for former customer with all past tenencies
                dispatch({ type: CASE_TENANCY_ID, payload: TenancySummaryEntry?.tenancySummary?.tenancyId })
                
                let paymentReference =
                  TenancySummaryEntry?.tenancySummary?.paymentReference;
                let tenancyResponseEntry = tenancyResponse.find(x => x.tenancyIdentifier === TenancySummaryEntry?.tenancySummary?.tenancyId)
                if (tenancyResponseEntry != undefined && tenancyResponseEntry != null) {
                  let tenancyGroup =
                    tenancyResponseEntry?.tenancyGroup;
                  if (tenancyGroup != undefined && tenancyGroup != null) {
                    dispatch(userChosentenancyGroup(tenancyGroup));
                  }
                }
                if (paymentReference != undefined && paymentReference != null) {
                  (async () => {
                    try {
                      const accountBalance = await api.getAccount(paymentReference);
                      getCustomeAccountData(true, paymentReference)
                      accountBalance["balance"].forEach((balance: any) => {
                        if (balance["balance-type"] == BalanceType.Livebalanced) {
                          let liveBalanced = balance["monetary-amount"]["value"];
                          let number =
                            liveBalanced != null
                              ? Math.abs(liveBalanced.toString().replace(/,/g, ""))
                              : 0.0;
                          if (number === 0) {
                            setShowRent(false)
                          }
                          else {
                            setShowRent(true)
                          }
                        }
                      })

                      dispatch(
                        userChosenAccountData(accountBalance)
                      );
                    }
                    catch (e) {
                      getCustomeAccountData(false, null)
                      dispatch(
                        userChosenAccountData(null)
                      );
                      setIsLoading(false)
                    }
                  })();
                  dispatch(userChosenAccountNumber(paymentReference));
                }
              }
            }
            associatedAssetsCount = associatedAssetsCount + associatedAssetsList.length;
            if (associatedAssetsCount > 1) {
              setArea(true);
            } else {
              setArea(false);
            }
          })
        }
        else {
          if (associatedAssetsCount > 1) {
            setArea(true);
          } else {
            setArea(false);
          }
        }
      }
      catch (e) {
        setIsLoading(false)
        console.error(e);
        const timerId = setTimeout(() => {
          props.history.push("/GenericError");
        }, 1000);
      }
    })()
  }

  const propertySelectOptions = {

    current: {
      options: optionsFromPremises(associatedAssetList),
      order: 1,
    },
    previous: {
      options: optionsFromPremises(inAssociatedAssetList),
      order: 2,
    },
  }
  dispatch(getAssetList(propertySelectOptions));
  const backButtonHandler = () => {
    if(finishJourney){
      props.history.push("/LandingView")
    }else
    history.goBack()
  };
  const callPageHandler = () => {
    if(!isP360Maintainence){
    props.history.push("/RepairAsset");
    }
  };
  const callCaseHandler = () => {
    backToCaseAsset(false)
    if (associatedAssetList.length === 1) {
      props.history.push("/CaseAsset");
    }
    else if (associatedAssetList.length > 1) {
      props.history.push("/SelectTenancy");
    }
  }
  const callVantageOnline = () => {
    const vatageURL = getDeepLink(CustomerOrProperty.Customer, customerId)  
    window.open(vatageURL, '_blank')
  }
  const dropDownFunction = () => {
    if (dropDown === "none")
      setDropDown("block");
    else
      setDropDown("none");
  }
  const handleChangeAssociate = (e: any) => {
    setIdentifiedCatalystProperty(false);
    setCatalystBannerMessageA("");
    setCatalystBannerMessageB("");
    dispatch(isCatalystProperty(false))
    setDefaultAssociatedAsset(e);
  
    callAlertApi(e);
    getAssociatedAsset(e);

    const result = associatedAssetList.find(x => x.key === e);
    if (result) {
      dispatch(userChosenPropertyAddress(associatedAssetList.find(x => x.key === e)?.value));
      dispatch(isActiveTenancy(true));
      setActiveTenancy(true);
    }
    else {
      dispatch(isActiveTenancy(false));
      setActiveTenancy(false);
    }
    let TenancySummaryEntry = customerData?.tenanciesSummaries.find(
      (st) => st.tenancySummary.premisesIds[0] === e
    );
    dispatch({ type: CASE_TENANCY_ID, payload: TenancySummaryEntry?.tenancySummary?.tenancyId })
    if (TenancySummaryEntry != undefined && TenancySummaryEntry != null) {

      let tenancyResponseEntry = tenanciesData.find(x => x.tenancyIdentifier === TenancySummaryEntry?.tenancySummary?.tenancyId)
      if (tenancyResponseEntry != undefined && tenancyResponseEntry != null) {
        let tenancyGroup =
          tenancyResponseEntry?.tenancyGroup;
        if (tenancyGroup != undefined && tenancyGroup != null) {
          dispatch(userChosentenancyGroup(tenancyGroup));
        }
      }
      let paymentReference =
        TenancySummaryEntry?.tenancySummary?.paymentReference;
      const tenancyType = TenancySummaryEntry?.tenancySummary?.tenancyType;
      if (paymentReference != undefined && paymentReference != null) {
        (async () => {
          try {
            const accountBalance = await api.getAccount(paymentReference);
            getCustomeAccountData(true, paymentReference)
            if (!result) {
              accountBalance["balance"].forEach((balance: any) => {
                if (balance["balance-type"] == BalanceType.Livebalanced) {
                  let liveBalanced = balance["monetary-amount"]["value"];
                  let number =
                    liveBalanced != null
                      ? Math.abs(liveBalanced.toString().replace(/,/g, ""))
                      : 0.0;
                  if (number === 0) {
                    setShowRent(false)
                  }
                  else {
                    setShowRent(true)
                  }
                }
              })
            }
            dispatch(
              userChosenAccountData(accountBalance)
            );
          }
          catch (e) {
            getCustomeAccountData(false, null)
            dispatch(
              userChosenAccountData(null)
            );
            setIsLoading(false)
          }
        })();
        dispatch(userChosenAccountNumber(paymentReference));
      }
    }
  };
  const getCustomeAccountData = (rentCard: boolean, paymentReference: string | null) => {
    (async () => {
      try {
        const accountCustomerResponse = await api.getAccountByCustomers(customerId);
        dispatch(userChosenCustomerAccountData(accountCustomerResponse));
        let accountCount = 0
        if (paymentReference != null) {
          accountCustomerResponse?.forEach((accountBalance: any) => {
            if (accountBalance.identifiers['pay-ref'].replace(/[^0-9]/g, '') == paymentReference) {
              if (accountBalance['account-type'] === AccountTypeList.REN ||
                accountBalance['account-type'] === AccountTypeList.DEP ||
                accountBalance['account-type'] === AccountTypeList.FD5 ||
                accountBalance['account-type'] === AccountTypeList.GRD ||
                accountBalance['account-type'] === AccountTypeList.MJW ||
                accountBalance['account-type'] === AccountTypeList.HOM

              ) {
                accountCount = accountCount + 1;
              }
            }
          })
          if (rentCard === true && accountCount < 2) {

            setShowRent(true)
          }
          else {
            if (accountCustomerResponse?.length !== 0) {
              setShowAccount(true)
            }
            else {
              setShowAccount(false)
            }
            setShowRent(false)
          }
        }
        else {
          if (accountCustomerResponse?.length !== 0) {
            setShowAccount(true)
          }
          else {
            setShowAccount(false)
          }
          setShowRent(false)
        }
        setIsLoading(false)
      }
      catch (e) {
        setIsLoading(false)
        console.error(e);
        const timerId = setTimeout(() => {
          props.history.push("/GenericError");
        }, 1000);
      }
    })()
  }

  const callAlertApi = (assetId: string) => {
    (async () => {
      try {
        const alertApiResponse = await api.getAlert(assetId);
        let finalAlertData: any[] = [];
        if (alertApiResponse) {
          alertApiResponse.map((alertData: any) => {
            if (alertData?.type === AlertValue.Rapid) {
              finalAlertData.push(alertData);
              setPropertyBannerCheck(true);
              dispatch(userChosenAlertValue(finalAlertData));
            } else {
              setPropertyBannerCheck(false);
            }
          })
        }
        else {
          setPropertyBannerCheck(false);
        }

      } catch (e) {
        console.error(e);
        const timerId = setTimeout(() => {
          props.history.push("/GenericError");
        }, 1000);
      }
    })()
  };
  return (
    <div className="border-t border-grey  bg-brandmark-image bg-no-repeat bg-right-60% bg-50% md:bg-35% lg:bg-20% bg-fixed">
      <div id="backmodal-div1" className="container mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0" >
        <p id="backmodal-p" className="text-teal text-xl mt-4 mb-8 md:mb-12 sm-pl-1">
          <a data-testid="custBackBtn" className="back-btn" onClick={backButtonHandler}>
            <i className="fas fa-angle-left mr-2"></i>
            <span>{t("back")}</span>
          </a>
        </p>
      </div>
      {!isLoading ? (
        <div className="container mx-auto">
          <div className="w-full px-2 lg:px-0">
            <div className="global-txt" >

              <div className="flex flex-row d-flex bd-highlight">
                <div className="bd-highlight">
                  <h1 className="mb-4">{customerData?.person.name.title} {customerData?.person.name.givenName} {customerData?.person.name.surname}</h1>
                  <p className="mt-2 global-txt">{t("CP008a")}</p>
                </div>

                
                <div className="menu-nav ms-auto bd-highlight ">
                  <div className="dropdown-container-h btn-group">
                    <div data-testid="dotsmenu"
                      className="btn btn-sm show three-dots-ast dwn_rt"
                      data-bs-toggle="dropdown"
                      onClick={dropDownFunction}>
                    </div>

                    <div className="dropdown-ast px-2 dropdown-menu show mt-45" style={{ display: dropDown }}>
                      {activeTenancy && isRepair ? (
                        <a data-testid="callpage" onClick={callPageHandler} className="text-black no-underline">
                          <div className={"cursor-pointer text-base text-black dropdown-item "  + (isP360Maintainence ? 'text-opacity-25 pe-none' : '')}>{t("Create_repair")}</div></a>
                      )
                        : null
                      }

                      {activeTenancy && isCaseCheck == 'true' ? (
                        <a data-testid="casepage" onClick={callCaseHandler} className="text-black text-base no-underline global-txt">
                          <div className="py-2 cursor-pointer text-black text-base no-underline dropdown-item">{t("CP104")}</div></a>
                      ) : null}

                      
                      {showVantage ? (
                        <a data-testid="vantagepage" onKeyDown={callVantageOnline} onClick={callVantageOnline} className="text-black text-base no-underline global-txt">
                          <div className="py-2 cursor-pointer text-black text-base no-underline dropdown-item">
                          {t("open_vantage")}
                          <span className="float-right pt-1"><img
                          src={openNewWindow}
                          alt=""
                          className="h-4 w-4 my-0 text-lg text-teal"
                        ></img></span>
                          
                          </div></a>
                       ) : null}  
                    </div>
                  </div>
                </div>
                


              </div>

            </div>

            <div className="border-b border-grey">
              <div className="my-2">

                {Area ? (
                  <div>
                    <span className="global-txt mb-4">{t("Select_your_tenancy")}</span>
                    <label className="block">
                      <PropertySelect
                        className="block w-full mt-2 rounded border-grey"
                        data-bdd="property-select"
                        id="property-select"
                        data-testid="propertyselect"
                        disabled={associatedAssetList.length + inAssociatedAssetList.length === 1}
                        defaultValue={defaultAssociatedAsset}
                        onChange={handleChangeAssociate}
                        options={propertySelectOptions}
                        variant="outlined"
                        fullWidth={true}
                      />
                    </label>
                  </div>
                ) : (
                  associatedAssetList.length > 0 ? (
                    associatedAssetList.map((e, key) => {
                      return (
                        <div>
                          <input
                            className="block w-full mt-2 rounded border-grey mob-view"
                            type="text"
                            aria-label="Search Address Data"
                            disabled={true}
                            key={key}
                            value={e.value.length > 42
                              ? e.value.slice(0, 42) + ".."
                              : e.value}
                          ></input>
                          <input
                            className="block w-full mt-2 rounded border-grey desk-view"
                            type="text"
                            aria-label="Search Address Data"
                            disabled={true}
                            key={key}
                            value={e.value}
                          ></input>
                        </div>
                      );
                    })
                  ) :
                    (
                      inAssociatedAssetList.map((e, key) => {
                        return (
                          <div>
                            {" "}
                            <input
                              className="block w-full mt-2 rounded border-grey mob-view"
                              type="text"
                              aria-label="Search Address"
                              disabled={true}
                              key={key}
                              value={e.value.length > 42
                                ? e.value.slice(0, 42) + ".."
                                : e.value}
                            ></input>
                            <input
                              className="block w-full mt-2 rounded border-grey desk-view"
                              type="text"
                              aria-label="Search Address"
                              disabled={true}
                              key={key}
                              value={e.value}
                            ></input>
                          </div>
                        );
                      })
                    )
                )
                }

              </div>
            </div>

            {!activeTenancy ? (
              <div className="alert p-0 mb-8">
                <div className="w-full px-2 py-2 bg-purple-20 flex items-center">
                  <div className="px-4 pr-8">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="text-purple-100 w-6 h-6">
                      <path d="M480 179.6C498.6 188.4 512 212.1 512 240C512 267.9 498.6 291.6 480 300.4V448C480 460.9 472.2 472.6 460.2 477.6C448.3 482.5 434.5 479.8 425.4 470.6L381.7 426.1C333.7 378.1 268.6 352 200.7 352H192V480C192 497.7 177.7 512 160 512H96C78.33 512 64 497.7 64 480V352C28.65 352 0 323.3 0 288V192C0 156.7 28.65 128 64 128H200.7C268.6 128 333.7 101 381.7 53.02L425.4 9.373C434.5 .2215 448.3-2.516 460.2 2.437C472.2 7.39 480 19.06 480 32V179.6zM200.7 192H192V288H200.7C280.5 288 357.2 317.8 416 371.3V108.7C357.2 162.2 280.5 192 200.7 192V192z"></path></svg>
                  </div>
                  <div className="flex items-center">
                    <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                      {t("CP027")}</p>
                  </div>
                </div>
              </div>
            ) : null}

            {identifiedCatalystProperty ? (
              <div className="warning-pre-line alert p-0">
                <div className="w-full px-2 py-2 bg-purple-20 flex items-center">
                  <div className="px-4 pr-8">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="text-purple-100 w-6 h-6">
                      <path d="M480 179.6C498.6 188.4 512 212.1 512 240C512 267.9 498.6 291.6 480 300.4V448C480 460.9 472.2 472.6 460.2 477.6C448.3 482.5 434.5 479.8 425.4 470.6L381.7 426.1C333.7 378.1 268.6 352 200.7 352H192V480C192 497.7 177.7 512 160 512H96C78.33 512 64 497.7 64 480V352C28.65 352 0 323.3 0 288V192C0 156.7 28.65 128 64 128H200.7C268.6 128 333.7 101 381.7 53.02L425.4 9.373C434.5 .2215 448.3-2.516 460.2 2.437C472.2 7.39 480 19.06 480 32V179.6zM200.7 192H192V288H200.7C280.5 288 357.2 317.8 416 371.3V108.7C357.2 162.2 280.5 192 200.7 192V192z"></path></svg>
                  </div>
                  <div className="flex items-center">
                    <div className="w-full text-left my-2 font-AvantGardeGothic-Md">
                      <div>{catalystBannerMessageA}</div>
                      <br/>
                      <div>{catalystBannerMessageB}</div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {isCautionBanner && preferenceCheck ? (
              <div className="pb-3">
                <CautionAlert  {...props} />
              </div>
            ) : null
            }
            {additionalAlert ? (
              <div className="pb-3">
                <AdditionalNeedAlert  {...props} />
              </div>
            ) : null
            }
            {isPropertyBanner && propertyBannerCheck ? (
              <div className="pb-3">
                <PropertyAlert  {...props} />
              </div>
            ) : null
            }

          </div>

          <div className="row mt-4 sm:px-2">
            {isCustomerCard ? (
              <div className="col-md-6">
                <CustomerCard  {...props} />
              </div>
            ) : null
            }
            {isWorkCard ? (
              <div className="col-md-6">
                <WorkCard  {...props} />
              </div>
            ) : null
            }
            {isCaseCard === false && isCaseCheck == 'true' ? (
              <div className="col-md-6">
                <CaseCard {...props} />
              </div>
            ) : null
            }
            {isAssetCard ? (

              <div className="col-md-6">
                <AssetCard {...props} />
              </div>
            ) : null
            }

            {isAccountCard ? (
              showAccount ? (
                !showRent ? (
                  <div className="col-md-6">
                    <AccountCard  {...props} />
                  </div>
                ) : null) : null
            ) : null}


            {isRentCard ? (
              showRent ? (
                <div className="col-md-6">
                  <RentCard {...props} />
                </div>
              ) : null
            ) : null}

            {isUCCCard && activeTenancy ? (
              <>
                <div className="col-md-6">
                  <UCCTenancyDetails {...props} />
                </div>
                <div className="col-md-6">
                  <UCCRentDetails {...props} />
                </div>
              </>
            ) : null
            }

            {isKeyContact ? (

              <div className="col-md-6 mt-4">
                <KeyContact {...props} />
              </div>
            ) : null
            }

          </div>

        </div>
      ) : (
        <div className="div-loader">
          <div className="SpinnerWrapper">
            <LoadingSpinner />
          </div>
        </div>
      )
      }
    </div>
  );
};

export default Customer360View;
