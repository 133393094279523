import {
  Container,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, useHistory } from "react-router-dom";
import Button from "../../Button/Button";
import * as api from "../../api";
import ExitModalAndSave from "../Modal/ExitModalAndSave";
import {
  userChosenEmergencyQuestion,
  userChosenCovidQuestion,
  userChosenContactDetails,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  isEditState,
  userChosenAdditionalDescription,
  userChosenVandalismQuestion,
  userChosenVandalismAdditionalInformation,
  userChosenVulnerabilityQuestion,
  userChosenVulnerabilityAdditionalInformation,
  userChosenDuplicateQuestion,
  resumeDraftRepair,
  backToRepairAsset,
  workOrderData,
  userChosenSaveExit,
  userChosenNotifyAll,
} from "../../ducks/redux/actions/userChoices";
import { isUndefinedNullOrEmpty } from "../../utils/translation/helper";
import { BackValue } from "../../models/shared/Enum";
import { WorksOrderRequest } from "../../models/WorksOrders/WorksOrderRequest";
import { workOrderRepairObject } from "../../ducks/redux/actions/getAddress";
import { PermissionsList } from "../../models/Permissions";
import {
  selectedRepairReasonPriorityOverride
} from "../../ducks/redux/actions/dashboardReducer.actions";
import { RepairPriority } from "../../models/Repair/CreateRepair";

interface RepairEmergencyProps extends RouteComponentProps<any> { }
const RepairEmergency: React.FC<RepairEmergencyProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [radiovalue, setRadioValue] = useState("");
  const [buttondisable, setsetButtonDisable] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();
  const [closeAfterSaving, setCloseAfterSaving] = useState(false);

  const userChosenEmergencyQuestionBackButton = useSelector(
    (state: any) => state.userChoicesReducer.userChosenEmergencyQuestion
  );
  const fetchedRepairReasonsSelection = useSelector(
    (state: any) => state.dashboardReducer.formState.fetchedRepairReasonsSelect
  );
  const EditState = useSelector(
    (state: any) => state.userChoicesReducer?.isEditState
  );
  const IsRecall = useSelector(
    (state: any) => state.userChoicesReducer?.IsRecall
  );
  const resumeDraftRepairVal = useSelector(
    (state: any) => state.userChoicesReducer.resumeDraftRepair
  );
  const userResumeDataVal = useSelector(
    (state: any) => state.userChoicesReducer.userResumeData
  );
  const userResumeDraftDataCache = useSelector(
    (state: any) => state.userChoicesReducer.userResumeDraftDataCache
  );
  const selectedproperty = useSelector(
    (state: any) => state?.dashboardReducer?.formState?.tracks[0]?.assetType
  );
  const workOrderObject = useSelector(
    (state: any) => state.addresses.workOrderRepair
  );
  const priority = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.selectedRepairReasonPriorityOverride ??
      state?.dashboardReducer?.formState?.selectedRepairReasonObject[0]?.priority
  );
  const getPermissionsData = useSelector(
    (state: any) => state?.userChoicesReducer?.userProfilePermissions
  );
  const choosePriorityOfRepairPermission = getPermissionsData?.indexOf(PermissionsList.workorder_choose_priority_of_repair) >= 0;

  const resumeRepairPriorityUpdatedToEmergency = useSelector(
    (state: any) => state.dashboardReducer.formState.resumeRepairReasonPriorityUpdatedToEmergency
  );

  useEffect(() => {
    if (props.history.action === "POP") {
      setRadioValue(userChosenEmergencyQuestionBackButton);
    }
    if (resumeDraftRepairVal) {
      setRadioValue(userChosenEmergencyQuestionBackButton);
      if (
        !(
          userResumeDataVal?.resumePriority === "Emergency" &&
          userResumeDataVal?.resumeSelectedProperty === "Property"
        ) && !resumeRepairPriorityUpdatedToEmergency
      ) {
        props.history.replace("/DuplicateRepair");
      }
    } else {
      setRadioValue(userChosenEmergencyQuestionBackButton);
      if (!(priority === "Emergency" && selectedproperty === "Property")) {
        props.history.replace("/DuplicateRepair");
      }
    }

    if (choosePriorityOfRepairPermission) {
      props.history.replace("/DuplicateRepair");
    }
  }, []);

  const updateSelection = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: any
  ) => {
    if (EditState === true) {
      dispatch(userChosenNotifyAll(null));
      dispatch(userChosenCovidQuestion(""));
      dispatch(userChosenVulnerabilityAdditionalInformation(""));
      dispatch(userChosenVulnerabilityQuestion("No"));
      dispatch(userChosenContactDetails(""));
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactName(""));
      dispatch(userChosenRelationDetails(""));
      dispatch(isEditState(false));
      dispatch(userChosenVandalismAdditionalInformation(""));
      dispatch(userChosenVandalismQuestion("No"));
      dispatch(userChosenAdditionalDescription(""));
      dispatch(userChosenDuplicateQuestion(""));
    }
    setRadioValue(value);
    dispatch(userChosenEmergencyQuestion(value));
  };

  const isEmergencyValue = t("Repair_Emergency_Radio1_Text");

  const backButtonHandler = () => {
    if (
      fetchedRepairReasonsSelection[0]?.additionalData.length > 0 &&
      fetchedRepairReasonsSelection[0]?.additionalData[0]?.description != ""
    ) {
      props.history.replace("/SupplementaryQuestion");
    } else {
      props.history.replace("/RepairLocation");
    }
  };

  const exitButtonHandler = () => {
    dispatch(backToRepairAsset(false))
    props.history.push("/LandingView");
  };

  const saveDraftAndExit = () => {
    dispatch(userChosenSaveExit(true))
    const worksOrder = new WorksOrderRequest(workOrderObject);
    dispatch(workOrderData(worksOrder))
    if (radiovalue === "") {
      dispatch(backToRepairAsset(false))
      props.history.push(`/WorkOrder360View/${worksOrder.identifiers?.worksOrderIdentifier}`);

      setTimeout(() => {
        dispatch(userChosenSaveExit(false))
      }, 6000);
    }
    setCloseAfterSaving(true);
    dispatch(backToRepairAsset(false))
    props.history.push(`/WorkOrder360View/${worksOrder.identifiers?.worksOrderIdentifier}`);

  };

  const UpdateWorkOrder = async () => {
    try {

      const _worksOrderRequest = new WorksOrderRequest(workOrderObject);
      const woRequestParameters =
        _worksOrderRequest.updateWorkOrderParameter(workOrderObject);


      woRequestParameters.isEmergency = radiovalue === t("Repair_Emergency_Radio1_Text") ? true : false;

      await api.updateWorkOrderRepair(
        woRequestParameters,
        workOrderObject.id,
        "eTag"
      );

      workOrderObject.isEmergency = radiovalue === t("Repair_Emergency_Radio1_Text") ? true : false;
      dispatch(selectedRepairReasonPriorityOverride(woRequestParameters.isEmergency ? RepairPriority.Emergency : RepairPriority.NextAvailable));
      dispatch(workOrderRepairObject(workOrderObject));
    } catch (e) {
      console.error(e);
      props.history.push("/GenericError");
    } finally {
    }
  };

  const callPageHandler = () => {

    UpdateWorkOrder();

    if (resumeDraftRepairVal) {
      const { emergencyQuestion } = userResumeDraftDataCache;
      if (emergencyQuestion !== radiovalue) {
        dispatch(resumeDraftRepair(false));
        dispatch(userChosenDuplicateQuestion(""));
        if (!IsRecall) {
          dispatch(userChosenAdditionalDescription(""));
          dispatch(userChosenVandalismQuestion("No"));
          dispatch(userChosenVandalismAdditionalInformation(""));
        }
        dispatch(userChosenCovidQuestion(""));
        dispatch(userChosenVulnerabilityQuestion("No"));
        dispatch(userChosenVulnerabilityAdditionalInformation(""));
        dispatch(userChosenContactDetails(""));
        dispatch(userChosenContactPhone(""));
        dispatch(userChosenContactName(""));
      }
    }

    if (radiovalue === "") {
      setErrorMessage(
        <div
          data-testid="alert-warning"
          className="error my-8"
          role="alert"
          placeholder="alert"
        >
          <div className="w-full px-2 py-2 bg-yellow flex items-center">
            <div className="px-4 pr-8">
              <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
            </div>
            <div className="flex items-center">
              <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                {t("SSR054")}
              </p>
            </div>
          </div>
        </div>
      );
      return;
    }
    props.history.replace("/DuplicateRepair");
  };

  return (
    <>
      <Container id="repemer-container" role="main" className="ctn-fht">
        <div id="repemer-back-div" className="container mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0" >
          <p id="repemer-p" className="text-teal text-xl mt-4 mb-8"><a className="back-btn" onClick={backButtonHandler}>
            <i className="fas fa-angle-left mr-2"></i>{t("back")}</a></p>
        </div>
        <div className="container">
          <div id="repairemer-main-div" className="h5 my-3 repair-text">
            <p
              id="repairemer-h1"
              className="text-purple-100 text-2xl mb-2 global-txt"
            >
              {t("Repair_Emergency_Header_Text")}
            </p>
          </div>
          <div id="repairemer-div1" className="repair-text mb-12">
            <RadioGroup
              id="repairemer-radiogrp"
              className="mb-2"
              onChange={updateSelection}
              defaultValue={radiovalue}
              value={radiovalue}
            >
              <fieldset><legend>
                <FormControlLabel
                  id="repairemer-label1"
                  value={t("Repair_Emergency_Radio1_Text")}
                  control={<Radio id="repairemer-radio1" />}
                  label={t("Repair_Emergency_Radio1_Text")}
                  placeholder="yes"
                />
              </legend></fieldset>
              <fieldset><legend>
                <FormControlLabel
                  id="repairemer-label2"
                  value={t("Repair_Emergency_Radio2_Text")}
                  control={<Radio id="repairemer-radio2" />}
                  label={t("Repair_Emergency_Radio2_Text")}
                  placeholder="next"
                />
              </legend></fieldset>
            </RadioGroup>
            {errorMessage}
          </div>
        </div>
        <div
          className="flex justify-between items-center pb-4 md:pb-8 container"
          id="repairemer-main-btn"
        >
          <ExitModalAndSave
            id="repairemer-exitmodal"
            exit={exitButtonHandler}
            saveDraftAndExit={saveDraftAndExit}
            closeAfterSaving={closeAfterSaving}
          />
          <Button
            id="repairemer-btn1"
            data-testid="continue"
            disabled={buttondisable}
            onClick={callPageHandler}
            className="button-class"
          >
            {t("continue")}{" "}
            <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
          </Button>
        </div>
      </Container>
    </>
  );
};

export default RepairEmergency;
