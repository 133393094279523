import { Key, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { trackEvent } from "../../appInsights/TelemetryService";
import { useDispatch, useSelector } from "react-redux";
import { RepairStatusField } from "../../models/Repair/Repair";
import * as api from "../../api";
import { formatDateWithFromCalendar } from "../../utils/formatting";
import { isNullOrUndefined } from "../../utils/formatting";
import { RepairChannel } from "../../models/Repair/RepairChannel";
import {RelatedPremisesType} from "../../models/Property/AdminUnits";
import { PremisesType } from "../../models/Customer/Premises";
import { PermissionsList } from "../../models/Permissions";
import { componentInformation, userChosenCustomerID, userChosenIsSurvey, workOrderData, } from "../../ducks/redux/actions/userChoices";
import { SurveyTypes } from "../../models/shared/Enum";

interface WorkCardProps extends RouteComponentProps<any> {
}
const WorkCard: React.FC<WorkCardProps> = (props) => {
  const { t } = useTranslation();
  var arrayErrorCode: string[] = [];
  let [errorCodeArray, setErrorCodeArray] = useState(arrayErrorCode);
  const [noWorkOrderTxt, setNoWorkOrderTxt] = useState("");
  const [dropDown, setDropDown] = useState("none");
  const associatedAsset = (state: any) => state?.addresses?.customerassociatedassets;
  const associatedAssetData = useSelector(associatedAsset);
  const tenancySummaryList = (state: any) => state?.addresses?.tenancysummarylist;
 
  const assetDetails = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.assetByIdDataObject
  );

  const isActiveTenancy = useSelector(
    (state: any) => state?.userChoicesReducer?.isActiveTenancy
  );
  const customerId = useSelector(
    (state: any) => state?.userChoicesReducer?.custommerID
  )
  const isP360Maintainence = useSelector(
    (state: any) => state.userChoicesReducer?.isP360RepairsMaintainenceValue
  );
  const tenancySummaryData = useSelector(tenancySummaryList);
    const [workOrderList, setWorkOrderList]: any = useState();
    const[isRepair, setIsRepair] = useState(false);
    const[isCreateSurvey, setIsCreateSurvey] = useState(false);
  const getPermissionsData = useSelector(
    (state: any) => state?.userChoicesReducer?.userProfilePermissions
  );
  const dispatch = useDispatch();

  useEffect(() => {
    trackEvent("WorkCard");
    dispatch(componentInformation("Cust360"));
    const showRepair = getPermissionsData?.indexOf(PermissionsList.create_customer360_wo_raise_a_repair);
    if (showRepair != -1) {
      setIsRepair(true)
    }
    else {
      setIsRepair(false)
    }
    const createSurvey= getPermissionsData?.indexOf(PermissionsList.create_p360_surveying1);
    {createSurvey != -1 ? setIsCreateSurvey(true) :  setIsCreateSurvey(false)}
    callWorkOrderApi();
    noWorkOrderFunction();

  }, [associatedAssetData]);

  const callWorkOrderApi=() =>{
    const propertiesIds: string[] = [];
    const premisesList: RelatedPremisesType[] = [];
    
    //Maping for id and assetType
    associatedAssetData?.map((element:any) => {
      element.map((e:any) => {
      const premisesEntry: RelatedPremisesType =
        new RelatedPremisesType();
      premisesEntry.premisesType = e.assetType as PremisesType;
      premisesEntry.premisesTypeId = e.id;
      premisesList.push(premisesEntry);
      propertiesIds.push(e.id);
      });
    });
    
    (async () => {
      try {
        let tenancyStartDate = tenancySummaryData[0]?.tenancySummary.tenancyStartDate ? tenancySummaryData[0]?.tenancySummary.tenancyStartDate : "";
        const status =
          RepairStatusField.Appointed +
          "," +
          RepairStatusField.PendingApproval +
          "," +
          RepairStatusField.OnHold +
          "," +
          RepairStatusField.Cancelled +
          "," +
          RepairStatusField.Completed +
          "," +
          RepairStatusField.Unappointed +
          "," +
          RepairStatusField.InProgress +
          "," +
          RepairStatusField.InDiagnosis +
          "," +
          RepairStatusField.ReportedToEMA +
          "," +
          RepairStatusField.CatalystTier2Triage +
          "," +
          RepairStatusField.CatalystEMATriage +
          "," +
          RepairStatusField.CatalystEMATriage;
        let queryParams: any;
        if (assetDetails?.id) {
          queryParams = {
            propertiesIds: propertiesIds,
            orderBy: "raisedDate desc",
            raisedSince: tenancyStartDate,
            priority: "",
            status,
            limit: 2,
            isTriage: ""
          };
        }
        else {
          queryParams = {
            propertiesIds,
            orderBy: "raisedDate desc",
            raisedSince: tenancyStartDate,
            priority: "",
            status,
            limit: 2,
            isTriage: ""
          };
        }
        const worksOrdersRepair = await api.getWorksOrdersApi(queryParams);
        let arr = [];
        arr.push(worksOrdersRepair);
        if (isNullOrUndefined(worksOrdersRepair?.totalCount) && worksOrdersRepair?.statusCode !== 200 && arr[0].statusCode !== 200) {
          const timerId = setTimeout(() => {
            props.history.push("/GenericError");
          }, 1000);
          return;
        }
        else {
            setWorkOrderList(worksOrdersRepair);
        }
      } catch (e) {
        console.error(e);
        const timerId = setTimeout(() => {
          props.history.push("/GenericError");
        }, 1000);
      }
    })();
  }

  const dropDownFunction = () => {
    if (dropDown === "none")
      setDropDown("block");
    else
      setDropDown("none");
  }



  function noWorkOrderFunction() {
    var tempArray = t("no_work_order_txt").split("?");
    setErrorCodeArray(tempArray);
  }
  const callPageHandler = () => {
    if(!isP360Maintainence){
    props.history.push("/RepairAsset");
    }
  };
  
 const callSurveyHandler = () => {
  if(!isP360Maintainence){
  dispatch(userChosenCustomerID(customerId));
  dispatch(userChosenIsSurvey(true));
  props.history.push("/SurveyAsset");
  }
 }


  const callWorkOrderPage = () => {
    props.history.push("/CustomerWorkOrder");
    };

    const callWorkOrder360 = (workOrder: any) => {
        dispatch(workOrderData(workOrder))
        props.history.push(`/WorkOrder360View/${workOrder.identifiers.worksOrderIdentifier}`)

    }
  return (
    <div className="relative pb-16 border border-grey bg-white shadow-lg rounded-lg cust-card mt-4">
      <div className="px-4 py-2 border-b border-grey mt-2 mb-3">
        <div className="flex flex-row d-flex bd-highlight items-center work-ht">
          <i className="fas fa-solid fa-wrench text-teal text-2xl mr-6 bd-highlight"></i>
          <h2 className="my-0 text-purple-100 bd-highlight">{t("worksOrderSurveys")}</h2>
          {
            isActiveTenancy ? (
              isRepair ? (
              <div className="menu-nav ms-auto bd-highlight">
                <div className="dropdown-container">
                  <div className="three-dots" data-testid="dots_menu" onClick={dropDownFunction}></div>

                  <div className="dropdown px-2 dropdown-margin py-2" style={{ display: dropDown }}>                   
                    <a data-testid="raise_rep" onClick={callPageHandler} className="text-black text-base no-underline global-txt"><div className={"py-2 cursor-pointer text-black text-base no-underline dropdown-item "  + (isP360Maintainence ? 'text-opacity-25 pe-none' : '')}>{t("Create_repair")}</div></a>
                    {isCreateSurvey ? (
                         <a data-testid="surveyraise" aria-hidden="true" onClick={callSurveyHandler} className="text-black text-base no-underline global-txt">
                         <div className={"py-2 cursor-pointer text-black text-base no-underline dropdown-item " + (isP360Maintainence ? 'text-opacity-25 pe-none' : '')}>{t("create_a_survey")}</div></a>
                   ): null}    
                  </div>

                </div>
              </div>
            )
              : null
            ) : <><div className="menu-nav ms-auto bd-highlight">
            <div className="dropdown-container">
              <div className="three-dots" data-testid="dots_menu" onClick={dropDownFunction}></div>

              <div className="dropdown px-2 dropdown-margin py-2" style={{ display: dropDown }}>                   
                {isCreateSurvey ? (
                     <a data-testid="surveyRaiseVoid" aria-hidden="true" onClick={callSurveyHandler} className="text-black text-base no-underline global-txt">
                     <div className={"py-2 cursor-pointer text-black text-base no-underline dropdown-item " + (isP360Maintainence ? 'text-opacity-25 pe-none' : '')}>{t("create_a_survey")}</div></a>
               ): null}          
              </div>
            </div>
          </div></>
          }
        </div>

      </div>

      {workOrderList?.worksOrders?.length ? (
      <div className="px-4 global-txt">
        <div className="text-dark flex justify-start">
          <div className="pr-4"><strong>{t("Asset_heading")}</strong>	</div>
        </div>
      </div>
      ):null}
    {isP360Maintainence && <div className="alert mb-0">
        <div className="alert p-0 container" role="alert">
                <div className="w-full px-3 py-2 bg-purple-20 flex items-center">
                  <div className="px-4 pr-8">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="text-purple-100 w-6 h-6">
                      <path d="M480 179.6C498.6 188.4 512 212.1 512 240C512 267.9 498.6 291.6 480 300.4V448C480 460.9 472.2 472.6 460.2 477.6C448.3 482.5 434.5 479.8 425.4 470.6L381.7 426.1C333.7 378.1 268.6 352 200.7 352H192V480C192 497.7 177.7 512 160 512H96C78.33 512 64 497.7 64 480V352C28.65 352 0 323.3 0 288V192C0 156.7 28.65 128 64 128H200.7C268.6 128 333.7 101 381.7 53.02L425.4 9.373C434.5 .2215 448.3-2.516 460.2 2.437C472.2 7.39 480 19.06 480 32V179.6zM200.7 192H192V288H200.7C280.5 288 357.2 317.8 416 371.3V108.7C357.2 162.2 280.5 192 200.7 192V192z"></path></svg>
                  </div>
                  <div className="flex items-center">
                    <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                      {t("repair_Changes")}</p>
                  </div>
                </div>
              </div>
              </div>}
      {!workOrderList?.worksOrders?.length ? (
        <p className="w-full text-left my-2 font-AvantGardeGothic-Md px-4 mt-4">
          {errorCodeArray[0]}
          <ul className="disc-list">
            <li> {errorCodeArray[1]}</li>
            <li> {errorCodeArray[2]}</li>
          </ul>
        </p>

      ) : (
        workOrderList?.worksOrders.map((_el: any, key: Key | null | undefined) => (
          <div className="px-4 global-txt " key={key}>
            <div className="border border-grey rounded-lg mb-4">
              <div className="bg-peach px-4 py-2 rounded-t-lg">
                <div className="flex flex-row justify-between items-center">
                  <div className="my-0 text-lg">
                      <a data-testid={"WorkOrder360Link" + _el?.identifiers?.worksOrderIdentifier} className="text-dark cursor-pointer" onClick = {() =>callWorkOrder360(_el)}>
                     <strong>{t("wo")} {" "}
                      {(_el?.statuses?.status === RepairStatusField.CatalystEMATriage) ? RepairStatusField.CatalystEMAStatus : (_el?.statuses?.status ===RepairStatusField.CatalystTier2Triage || _el?.statuses?.status ===RepairStatusField.CatalystDefectTriage) ? _el?.statuses?.status : _el.identifiers?.jobNumber}
                      </strong>
                      </a></div>
                  <div className="my-0 text-lg"><strong>
                  {(_el?.job?.repairType === SurveyTypes.survey) ? SurveyTypes.Survey : ((_el?.job?.workProgramme != null && _el?.job?.workProgramme !== "" && _el?.job?.workProgramme !== undefined) ? t(_el?.job?.workProgramme) : t("RESP"))}
                    </strong></div>
                </div>
              </div>

              <div className="px-4 py-2 flex justify-between border-b border-grey">
                <div className="pr-4">{t("Date_Creation")}
                </div>
                <div className="text-right">  {formatDateWithFromCalendar(_el?.raisedDate || '')}</div>
              </div>

              <div className="px-4 py-2 flex justify-between border-b border-grey">
                <div className="pr-4">{t("property_type_txt")}
                </div>
                <div className="text-right">{_el?.asset?.type?.assetTypeName}</div>
              </div>

              <div className="px-4 py-2 flex justify-between border-b border-grey">
                <div className="pr-4">{t("High_Level_Description")}
                </div>
                <div className="text-right">
                              {(_el?.job.trade === "Unknown" &&
                                _el?.channel !== RepairChannel.CustomerSelfService &&
                                _el?.channel !== RepairChannel.ERGO &&
                                _el?.channel !== RepairChannel.ColleagueSelfServe ) 
                                ? t("RepairDescription_ErrorMessage")
                                : _el?.channel ===
                                  RepairChannel.CustomerSelfService ||
                                  (_el?.channel === RepairChannel.ERGO) ||
                                  (_el?.channel === RepairChannel.ColleagueSelfServe)
                                  ? _el?.job.description?.split(".")[0]
                                  : _el?.job.trade}
                  </div>
              </div>

              <div className="px-4 py-2 flex justify-between border-b border-grey">
                <div className="pr-4">{t("status_txt")}
                </div>
                <div className="text-right" data-testid="status">
                {(_el?.statuses.status === "In Diagnosis" &&
                    (_el?.statuses.isPotentialDuplicate === true ||
                      _el?.statuses.isVulnerable === true ||
                      _el?.job.sorCode === "N/A" || _el?.job.sorCode === "NA") && _el.statuses.isSubmitted)
                    ? "Under review"
                    : 
                _el?.statuses.status === "In Diagnosis"
                                  ? t("draft")
                                  :  (_el?.statuses.status  === "Unappointed" || _el?.statuses.status  === "Reported to EMA")
                                    ? (_el?.job?.repairType === SurveyTypes.survey ? t("assigned_surveyor") : t("assigned_contractor"))
                                     :  (_el?.statuses?.status === RepairStatusField.CatalystEMATriage) ? RepairStatusField.CatalystEMAStatus : _el?.statuses.status}</div>
              </div>

            </div>
          </div>
        ))
      )}

{workOrderList?.worksOrders?.length > 0 ? (
      <div className="px-4 global-txt w-full py-4 flex justify-between absolute bottom-0">
        <div>
          <a href="" data-testId="work_order" className="text-teal no-underline hover:underline" onClick={callWorkOrderPage}>{t("view_workorders")} &gt;</a>
        </div>
      </div>):null}
    </div>
  )
};
export default WorkCard;