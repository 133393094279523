import React, { useEffect } from "react";
import Modal from "./index";
import Button from "../../Button/Button";
import { ModalProps } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as api from "../../api";
import {
	userChosenPropertyId,
	userChosenPropertyType,
	userChosenPropertyAddress,
	userChosenRepairLocationId,
	userChosenRepairLocation,
	userChosenHighLevelDescription,
	userChosenSupplementaryQuestions,
	userChosenEmergencyQuestion,
	userChosenDuplicateQuestion,
	userChosenAdditionalDescription,
	userChosenVandalismQuestion,
	userChosenVandalismAdditionalInformation,
	userChosenCovidQuestion,
	userChosenVulnerabilityQuestion,
	userChosenVulnerabilityAdditionalInformation,
	userChosenContactDetails,
	userChosenUPRN,
	userChosenSOR,
	userChosenSTATUS,
	userChosenEligibilityErrorCode,
	userChosenContactPhone,
	userChosenContactName,
	userChosenRelationDetails,
	userChosenSaveExit
} from "../../ducks/redux/actions/userChoices";

import { 
	selectedRepairReasonPriorityOverride, 
	sorErrorCode
} from "../../ducks/redux/actions/dashboardReducer.actions";
import {
	getHeatingHotWaterCase,
	getMaintResp,
	getRepairResp,
	getContractorName,
	getDeterminedContractorDetails,
	getContractorPayload,
} from "../../ducks/redux/actions/getAddress";

const ExitModalAndSave: React.FC<ModalProps> = ({ exit, saveDraftAndExit, closeAfterSaving }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = React.useState(false);	

	const [isDisable, setIsDisable] = React.useState(false);	
   
	const isSaveExit = useSelector(
		(state: any) => state.userChoicesReducer?.userChosenSaveExit
	  );

	const workOrderObject = useSelector(
		(state: any) => state.addresses.workOrderRepair
	);
	const userChosenSORValue = useSelector(
		(state: any) => state.userChoicesReducer.userChosenSOR
		);
		const userChosenRepairLocationValue = useSelector(
			(state: any) => state.userChoicesReducer.userChosenRepairLocation
			);
		
	useEffect(() => {
		const urlRepairLoc = window.location.pathname.slice(1,window.location.pathname.length);
		if(userChosenRepairLocationValue === "" && urlRepairLoc === "RepairLocation" || userChosenSORValue === "" && urlRepairLoc === "RepairLocation"){
			setIsDisable(true)
		}
		else{
			setIsDisable(false)
		}
	})

	const clearUserStates = async () => {
		dispatch(sorErrorCode(""));		
		dispatch(userChosenPropertyId(""));
		dispatch(userChosenPropertyType(""));
		dispatch(userChosenPropertyAddress(""));
		dispatch(userChosenRepairLocationId(0));
		dispatch(userChosenRepairLocation(""));
		dispatch(userChosenHighLevelDescription(""));
		dispatch(userChosenSupplementaryQuestions(""));
		dispatch(userChosenEmergencyQuestion(""));
		dispatch(userChosenDuplicateQuestion(""));
		dispatch(userChosenAdditionalDescription(""));
		dispatch(userChosenVandalismQuestion("No"));
		dispatch(userChosenVandalismAdditionalInformation(""));
		dispatch(userChosenCovidQuestion(""));
		dispatch(userChosenVulnerabilityQuestion(""));
		dispatch(userChosenVulnerabilityAdditionalInformation(""));
		dispatch(userChosenContactDetails(""));
		dispatch(userChosenUPRN(""));
		dispatch(userChosenSOR(""));
		dispatch(getRepairResp(""));
		dispatch(getHeatingHotWaterCase(""));
		dispatch(getMaintResp(""));
        dispatch(getDeterminedContractorDetails(""));
        dispatch(getContractorName(""));
        dispatch(getContractorPayload(""));
		dispatch(userChosenSTATUS(""));
		dispatch(userChosenEligibilityErrorCode(""));
		dispatch(userChosenContactPhone(""));
		dispatch(userChosenContactName(""));
		dispatch(userChosenRelationDetails(""));
		dispatch(selectedRepairReasonPriorityOverride(null));
		exit();
		if (workOrderObject) {
			const getWorkOrderResult: any = await api.getRepair(workOrderObject.id);
			 const deleteWorkOrderResponse: any = await api.deleteWorkOrder(workOrderObject.id, getWorkOrderResult.eTag.toString());
		}		
	}

	if (closeAfterSaving) {		
		setIsOpen(false)
	}
	

	useEffect(() => {		
		setIsDisable(isSaveExit)
	  }, [isSaveExit]);


	return (
		<>
			<div><a id="exitmodal-btn1" className="text-teal exit-btn a-style cursor-pointer ml-3" onClick={() => setIsOpen(true)}>{t("exit_button")}</a></div>
			<Modal open={isOpen} cssClasses="modalPopUp2">
				<div id="exitmodal-div1" className="modalPopUpContainer container my-1">
					<div id="exitmodal-div2" className="py-lg-0 py-sm-0 h5 my-5 question-font-size text-center global-txt">
						{t("SSR030ai")}
						<br></br><br></br>
						{t("SSR030aii")}
					</div>
					<div id="exitmodal-div3" className='modalButtonContainer button-positions flex flex-wrap text-center pad-bot pt-lg-0'>
						<Button id="exitmodal-btn2" className="button-class" onClick={() => setIsOpen(false)}>{t("return_repair_button")}
							<i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i></Button>
						<Button id="exitmodal-btn3" onClick={clearUserStates} className="button-class">{t("leave_button")}
							<i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i></Button>
						<Button disabled={isDisable} id="exitmodal-btn4" onClick={saveDraftAndExit} className="button-class">{t("save_and_exit_button")}
							<i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i></Button>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default ExitModalAndSave;
