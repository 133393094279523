import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, useHistory } from "react-router-dom";
import LoadingSpinner from "../../storybook/LoadingSpinner";
import { PermissionsList } from "../../models/Permissions";
import WorkOrderDetails from "../WorkOrderDetails";
import WorkOrderCustomerCard from "../WorkOrderCustomerCard";
import * as api from "../../api";
import {
  assetDataStatus,
  getWorkOrderObject,
  isActiveTenancy,
  loadDuplicateRepairPage,
  resumeDraftRepair,
  userChosenAdditionalDescription,
  userChosenAdditionalDescriptionCOUNT,
  userChosenAlertValue,
  userChosenAssetHierarchy360,
  userChosenAssetValue,
  userChosenBackValue,
  userChosenContactDetails,
  userChosenContactName,
  userChosenContactPhone,
  userChosenCovidQuestion,
  userChosenDuplicateEntity,
  userChosenDuplicateQuestion,
  userChosenEligibilityErrorCode,
  userChosenEmergencyQuestion,
  userChosenHighLevelDescription,
  userChosenIsRecall,
  userChosenIsReporter,
  userChosenPropertyAddress,
  userChosenPropertyId,
  userChosenPropertyType,
  userChosenRelationDetails,
  userChosenRepairLocation,
  userChosenRepairLocationId,
  userChosenResumeRepair,
  userChosenSOR,
  userChosenSTATUS,
  userChosenSupplementaryQuestions,
  userChosenUPRN,
  userChosenVandalismAdditionalInformation,
  userChosenVandalismQuestion,
  userChosenVulnerabilityAdditionalInformation,
  userChosenVulnerabilityQuestion,
  userResumeData,
  userResumeDraftDataCache,
  workOrderData,
  userChosenCurrentValue,
  userChosenSaveExit,
  originalWorkOrderData,
  userChosenAssociatedAssetsList,
  customerPreferenceData,
  customerAdditionalAlertData,
  isRepairMediaResume,
  clearMediaFiles,
  userChosenUploadedMediaIdentifiers,
  isCatalystProperty,
  catalystStatus,
  userChosenIsSurvey,
  userChosenNotifyAll,
  userChosenOtherBalance,
  repairAddressData,
  selectedTenancyGroup,
  userChoiceRepairReasons,
} from "../../ducks/redux/actions/userChoices";
import {
  getCustomerId,
  getReferenceNumber,
  repairHistoryData,
  saveAssociatedAssests,
  saveCustomerDetails,
  saveCustomerphoneNumber,
  selectedTenacyType,
  workOrderRepairObject,
  getMaintResp,
  getHeatingHotWaterCase,
  getRepairResp,
  saveAvailabilityResponse,
  getContractorName,
  getDeterminedContractorDetails,
  getContractorPayload,
  saveCustomerId,
} from "../../ducks/redux/actions/getAddress";
import WorkOrderChronologySummary from "../WorkOrderChronologySummary";
import { AlertValue, BackValue, WOTypes } from "../../models/shared/Enum";
import CautionAlert from "../CautionAlert";
import {
  CurrentRepair,
  ReportContactDetails,
  RepairStatusField,
} from "../../models/Repair/Repair";
import moment from "moment";
import CancelModelRepair from "../Modal/CancelModelRepair";
import CancelModelSurvey from "../Modal/CancelModelSurvey";
import PropertyAlert from "../PropertyAlert";
import { isNullOrUndefined } from "../../utils/formatting";
import {
  userTypedRepairReasons,
  updateDashboardForm,
  assetByIdDataObject,
  selectedRepairReasonPriorityOverride,
  resumeRepairReasonPriorityUpdatedToEmergency,
  fetchRepairReasonsSelect,
} from "../../ducks/redux/actions/dashboardReducer.actions";
import CustomerResponseDetails from "../../models/Customer/CustomerResponseDetails";
import { PremisesType } from "../../models/Customer/Premises";
import AssetCard from "../AssetCard";
import AssetDetails from "../../models/Customer/AssetDetails";
import { isWithinSixMonths } from "../../utils/formatting";
import { WorksOrderRequest } from "../../models/WorksOrders/WorksOrderRequest";
import CustomerName from "../../models/Customer/CustomerName";
import AdditionalNeedAlert from "../AdditionalNeedAlert";
import { isUndefinedNullOrEmpty } from "../../utils/translation/helper";
import NotesCard from "../NotesCard";
import { woRedirectFromEmailStatus } from "../../ducks/redux/actions/FinancialReferral";
interface WorkOrder360ViewProps extends RouteComponentProps<any> {
  Appointment?: string;
  Priority?: string;
  Contractor?: string;
  Description?: string;
  workOrderID?: string;
  status?: string;
  repairReference?: string;
  area?: string;
  fullDescription?: string;
  appointmentDate?: string;
  ContractorsList?: string[];
  siteIdentifier?: string;
  associtaedAsset?: string;
}
const WorkOrder360View: React.FC<WorkOrder360ViewProps> = (props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  let [draftRepair, setDraftRepair] = useState<any>();
  const { t } = useTranslation();
  const [isWorkCard, setIsWorkCard] = useState(false);
  const [isCustomerCard, setIsCustomerCard] = useState(false);
  const [isAssetCard, setIsAssetCard] = useState(false);
  const [dropDown, setDropDown] = useState("none");
  const [assetUprn, setAssetUprn] = useState("");
  const [isWorkOrderCustomerCard, setIsWorkOrderCustomerCard] = useState(false);
  const [isPropertyBanner, setPropertyBanner] = useState(false);
  const [isCancelButton, setIsCancelButton] = useState(false);
  const [isAppointment, setIsAppointment] = useState(false);
  const [isAppointmentRole, setIsAppointmentRole] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [area, setArea] = useState("");
  const [contractor, setContractor] = useState("");
  const [propertyCheck, setPropertyCheck] = useState(false);
  const [isCautionBanner, setIsCaution] = useState(false);
  const [customerDetails, setCustomerDetails] =
    useState<CustomerResponseDetails>();
  const [isAssetId, setIsAssetId] = useState(false);
  const [activeTenancy, setActiveTenancy] = useState(false);
  const [assetValue, setAssetValue] = useState("");
  const [isWorkOrderLoaded, setIsWorkOrderLoaded] = useState(false);
  const [customerIdentifier, setCustomerIdentifier] = useState("");
  const [isCreateSurvey, setIsCreateSurvey] = useState(false);
  let assetRegionValue: string = "";
  const [status, setStatus] = useState("");
  const [jobNumber, setJobNumber] = useState("");
  const [identifiedCatalystProperty, setIsCataLyst] = useState(false);
  const [catalystBannerMessageA, setCatalystBannerMessageA] = useState("");
  const [catalystBannerMessageB, setCatalystBannerMessageB] = useState("");
  const [isRepairFullyCreated, setIsRepairFullyCreated] = useState(false);
  const [woSerialNumber, setWoSerialNumber] = useState("");
  const [woSequenceNumber, setWoSequenceNumber] = useState("");
  const [isUnderReviewTriage, setIsUnderReviewTriage] = useState(false);
  const [isSelectAppointmentChosen, setIsSelectAppointmentChosen] =
    useState(false);
  const [woHadDemobilizedContractorSite, setWOHadDemobilizedContractorSite] =
    useState(false);
  const getPermissionsData = useSelector(
    (state: any) => state?.userChoicesReducer?.userProfilePermissions
  );
  let [reviewTriageRepairPermission] = useState(
    getPermissionsData?.indexOf(
      PermissionsList.workorder_review_triage_repair
    ) >= 0
  );
  let [noTriagePermission] = useState(
    getPermissionsData?.indexOf(
      PermissionsList.workorder_no_triage_forheatingandhotwaterrepairs
    ) >= 0
  );
  let [duplicateOverridePermissions] = useState(
    getPermissionsData?.indexOf(
      PermissionsList.workorder_override_duplicate_repairs
    ) >= 0
  );
  let [choosePriorityPermission] = useState(
    getPermissionsData?.indexOf(
      PermissionsList.workorder_choose_priority_of_repair
    ) >= 0
  );

  const history = useHistory();
  const OnHoldContractor = "CONTRACTORSITE_ON_HOLD";
  enum LandlordAppConfigValues {
    IsCheckForLandlord = "IsCheckForLandlordP360",
    LandlordValues = "LandlordValues",
    CatalystRepairDisabledAreas = "CatalystRepairDisabledAreasP360",
    CatalystRegistrationAreas = "CatalystRegistrationAreas",
  }

  const workOrders = useSelector(
    (state: any) => state?.userChoicesReducer?.workOrderData
  );

  const assetDetails = useSelector(
    (state: any) => state?.dashboardReducer?.formState?.assetByIdDataObject
  );
  const vandalismConfigValue = useSelector(
    (state: any) => state?.userChoicesReducer?.vandalismtoggleValue
  );
  const covidConfigValue = useSelector(
    (state: any) => state?.userChoicesReducer?.isRepairCovidAllowed
  );

  const isContractorIntegerated = useSelector(
    (state: any) => state?.addresses?.getIntegeratedContractor
  );
  const finishJourney = useSelector(
    (state: any) => state?.financialReferralReducer?.isJourneyFinish
  );

  const isRepairMedia = useSelector(
    (state: any) => state?.userChoicesReducer?.isRepairMediaAllowed
  );
  const userChosenContactNameVal = useSelector(
    (state: any) => state.userChoicesReducer.userChosenContactName
  );
  const isP360Maintainence = useSelector(
    (state: any) => state.userChoicesReducer?.isP360RepairsMaintainenceValue
  );
  const demobilizedContractorList = useSelector(
    (state: any) => state?.userChoicesReducer?.demobilizedContractorSites
  );
  let [customerID] = useState("");


  let customerResponse: any;

  const [isRecallEligible, setIsRecallEligible] = useState(false);

  const alertApiFunction = (assetId: string) => {
    (async () => {
      try {
        const alertApiResponse = await api.getAlert(assetId);
        let finalAlertData: any[] = [];
        if (
          alertApiResponse !== null &&
          alertApiResponse !== "" &&
          alertApiResponse !== undefined
        ) {
          alertApiResponse.map((alertData: any) => {
            if (alertData?.type === AlertValue.Rapid) {
              finalAlertData.push(alertData);
              setPropertyCheck(true);
              dispatch(userChosenAlertValue(finalAlertData));
            }
          });
        } else {
          setPropertyCheck(false);
        }
      } catch (e) {
        console.error(e);
        const timerId = setTimeout(() => {
          props.history.push("/GenericError");
        }, 1000);
      }
    })();
  };
  const clear = () => {
    dispatch(catalystStatus(""));
    dispatch(userChosenPropertyAddress(""));
    dispatch(userChosenRepairLocationId(0));
    dispatch(userChosenRepairLocation(""));
    dispatch(getReferenceNumber(null));
    dispatch(userTypedRepairReasons(""));
    dispatch(userChosenHighLevelDescription(""));
    dispatch(userChosenSupplementaryQuestions(""));
    dispatch(userChosenEmergencyQuestion(""));
    dispatch(userChosenDuplicateQuestion(""));
    dispatch(userChosenDuplicateEntity(""));
    dispatch(userChosenAdditionalDescription(""));
    dispatch(userChosenVandalismQuestion("No"));
    dispatch(userChosenVandalismAdditionalInformation(""));
    dispatch(userChosenCovidQuestion(""));
    dispatch(userChosenVulnerabilityQuestion(""));
    dispatch(userChosenVulnerabilityAdditionalInformation(""));
    dispatch(userChosenContactDetails(""));
    dispatch(userChosenUPRN(""));
    dispatch(userChosenPropertyId(""));
    dispatch(userChosenSOR(""));
    dispatch(getRepairResp(""));
    dispatch(getHeatingHotWaterCase(""));
    dispatch(getMaintResp(""));
    dispatch(getDeterminedContractorDetails(""));
    dispatch(getContractorName(""));
    dispatch(getContractorPayload(""));
    dispatch(userChosenSTATUS(""));
    dispatch(userChosenEligibilityErrorCode(""));
    dispatch(userChosenContactPhone(""));
    dispatch(userChosenRelationDetails(""));
    dispatch(selectedTenacyType(""));
    dispatch(workOrderRepairObject(null));
    dispatch(userChosenIsReporter(false));
    dispatch(loadDuplicateRepairPage(false));
    dispatch(userChosenResumeRepair(false));
    dispatch(userChosenSaveExit(false));
    dispatch(customerPreferenceData({}));
    dispatch(customerAdditionalAlertData({}));
    dispatch(clearMediaFiles([]));
    dispatch(userChosenUploadedMediaIdentifiers([]));
    dispatch(userChosenNotifyAll(null));
    dispatch(saveAvailabilityResponse(null));
    dispatch(repairHistoryData(null));
    dispatch(assetByIdDataObject(null));
    dispatch(selectedTenancyGroup(""));
    dispatch(userChosenIsSurvey(false));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(true);

    if (getPermissionsData?.length > 0) {
      let wo360ViewHref = window.location.href;
      const regexForWOGuidFromUrl = new RegExp(
          /WorkOrder360View\/([a-z0-9-']*)/g,
          "i"
        ),
        workOrderIdFromUrl = regexForWOGuidFromUrl.exec(wo360ViewHref),
        woIdFromUrl = workOrderIdFromUrl ? workOrderIdFromUrl[1] : "";
      let isRedirectRequire = window.location.search;
      if (isNullOrUndefined(woIdFromUrl)) {
        // Redirect to Landig page if WO Guid not present in URL
        props.history.push("/LandingView");
      }

      (async () => {
        dispatch(userChosenAssociatedAssetsList(null));
        setActiveTenancy(false);
        dispatch(saveAssociatedAssests(null));
        dispatch(originalWorkOrderData(null));
        dispatch(isActiveTenancy(false));
        dispatch(userChosenIsRecall(false));
        dispatch(userChosenBackValue(BackValue.WorkOrder360View));
        dispatch(userChosenCurrentValue(BackValue.WorkOrder360View));
        dispatch(userChosenAssetHierarchy360(BackValue.WorkOrder360View));
        clear();

        const workOrderResponse = await api.getWorkOrderEntity(woIdFromUrl);
        // Setting isDraft true while resuming so that we can attempt WO creation for WO which failed job creation in ERGO
        if (workOrderResponse) {
          workOrderResponse.isDraft = isNullOrUndefined(
            workOrderResponse?.jobNo
          );
          setWOHadDemobilizedContractorSite(
            demobilizedContractorList?.some(
              (x: string) =>
                x.toLowerCase() === workOrderResponse?.contractor?.toLowerCase()
            )
          );
          setDraftRepair(workOrderResponse);
          alertApiFunction(workOrderResponse?.assetIdentifier);
          if (!isNullOrUndefined(workOrderResponse?.assetIdentifier)) {
            let assetIdentifier = workOrderResponse?.assetIdentifier;
            await callGetAssetByIdApi(assetIdentifier);
          }

          if (workOrderResponse.jobNo) {
            setIsRepairFullyCreated(true);
            const [serialNumber, sequenceNumber] =
              workOrderResponse.jobNo.split("/");
            setWoSerialNumber(serialNumber);
            setWoSequenceNumber(sequenceNumber);
          }
          await getCancelRepairEligibility(
            workOrderResponse,
            workOrders?.asset?.type?.assetTypeName
          );
          await getIsRecallEligibility(workOrderResponse);

          if (workOrderResponse?.area !== "Property") {
            setActiveTenancy(true);
            dispatch(isActiveTenancy(true));
          }

          if (
            workOrderResponse?.status === RepairStatusField.CatalystEMATriage
          ) {
            setJobNumber(RepairStatusField.CatalystEMAStatus);
            setStatus(RepairStatusField.CatalystEMAStatus);
            dispatch(catalystStatus(RepairStatusField.CatalystEMAStatus));
          } else if (
            workOrderResponse?.status ===
              RepairStatusField.CatalystDefectTriage ||
            workOrderResponse?.status === RepairStatusField.CatalystTier2Triage
          ) {
            setJobNumber(workOrderResponse?.status);
            setStatus(workOrderResponse?.status);
            dispatch(catalystStatus(workOrderResponse?.status));
          } else {
            dispatch(catalystStatus(workOrderResponse?.status));
            setStatus(workOrderResponse?.status);
          }

          // Redirecting triage email work order when api response comes
          let routeChange: boolean =
            isRedirectRequire === "?reviewWorkOrder=true";
          if (routeChange) {
            reviewTriageRepairPermission =
              getPermissionsData?.indexOf(
                PermissionsList.workorder_review_triage_repair
              ) >= 0;
            noTriagePermission =
              getPermissionsData?.indexOf(
                PermissionsList.workorder_no_triage_forheatingandhotwaterrepairs
              ) >= 0;
            duplicateOverridePermissions =
              getPermissionsData?.indexOf(
                PermissionsList.workorder_override_duplicate_repairs
              ) >= 0;
            choosePriorityPermission =
              getPermissionsData?.indexOf(
                PermissionsList.workorder_choose_priority_of_repair
              ) >= 0;
            dispatch(userChosenResumeRepair(true));
            dispatch(workOrderRepairObject(workOrderResponse));
            dispatch(woRedirectFromEmailStatus(true));
            dispatch(userChosenSOR(workOrderResponse?.sorCode));
            //Shifted redirect under below function
            resumeRepairHandler(workOrderResponse, true);
          }
        }
      })();

      const showCustomer = getPermissionsData?.indexOf(
          PermissionsList.view_workorder360_customer_summary_card
        ),
        showWork = getPermissionsData?.indexOf(
          PermissionsList.view_workorder360_work_order_details_card
        ),
        showAsset = getPermissionsData?.indexOf(
          PermissionsList.view_workorder360_asset_hierarchy_summary_card
        ),
        showProperty = getPermissionsData?.indexOf(
          PermissionsList.view_workorder360_property_alerts_banner
        ),
        showisAppointmentRole = getPermissionsData?.indexOf(
          PermissionsList.view_digital_journey_appoint_a_repair
        ),
        showCaution = getPermissionsData?.indexOf(
          PermissionsList.view_workorder360_caution_alerts_banner
        );

      setIsCustomerCard(showCustomer != -1);
      setIsWorkCard(showWork != -1);
      setIsAssetCard(showAsset != -1);
      setPropertyBanner(showProperty != -1);
      setIsAppointmentRole(showisAppointmentRole != -1);
      setIsCaution(showCaution != -1);

      (async () => {
        try {
          setIsWorkOrderLoaded(false);
          const workOrderDataResponse = await api.getWorkOrder(woIdFromUrl);
          const isUnderReviewTriage =
            workOrderDataResponse.statuses?.isSubmitted &&
            (workOrderDataResponse.statuses?.isPotentialDuplicate ||
              workOrderDataResponse?.customer?.vulnerability?.isVulnerable ||
              workOrderDataResponse?.job?.sorCode === "NA");
          setIsUnderReviewTriage(isUnderReviewTriage ?? false);
          if (
            workOrderDataResponse &&
            workOrderDataResponse !== undefined &&
            workOrderDataResponse !== null
          ) {
            dispatch(workOrderData(workOrderDataResponse));
            dispatch(originalWorkOrderData(workOrderDataResponse));
            dispatch(
              updateDashboardForm({
                assetId:
                  workOrderDataResponse?.identifiers?.asset?.assetIdentifier,
              })
            );
            setIsWorkOrderLoaded(true);
            setIsAssetId(true);
            dispatch(getWorkOrderObject(workOrderDataResponse));
          }
          setIsLoading(false);
        } catch (e) {
          setIsLoading(false);
          console.error(e);
          props.history.push("/GenericError");
        }
        const createSurvey = getPermissionsData?.indexOf(
          PermissionsList.create_p360_surveying1
        );
        setIsCreateSurvey(createSurvey != -1);
      })();
    }
  }, [getPermissionsData]);

  const getIsRecallEligibility = async (workOrderResponse: CurrentRepair) => {
    const showisRecallRole = getPermissionsData?.indexOf(
      PermissionsList.view_digital_journey_recall_a_repair
    );
    const eligibleStatuses = ["closed", "completed"];
    const isStatusEligibleForRecall =
      workOrderResponse &&
      workOrderResponse.status &&
      eligibleStatuses.indexOf(workOrderResponse.status.toLowerCase()) > -1;
    const isMPT =
      workOrderResponse.contractor &&
      workOrderResponse.contractor != "" &&
      workOrderResponse.contractor.toLowerCase().indexOf("mpt") > -1;

    const eligibleWorkProgramme = ["RESP", "GASRESP", "MERESP"];
    const checkRecall: boolean =
      eligibleWorkProgramme.indexOf(
        workOrderResponse.workProgramme?.toUpperCase()
      ) > -1;

    if (
      showisRecallRole > 0 &&
      isStatusEligibleForRecall &&
      workOrderResponse.priority == "Next Available" &&
      checkRecall &&
      !isMPT &&
      isWithinSixMonths(workOrderResponse.completedDate)
    ) {
      setIsRecallEligible(true);
    } else {
      setIsRecallEligible(false);
    }
  };

  const callGetAssetByIdApi = async (id: string) => {
    try {
      let associtaedAssets: AssetDetails[] = [];
      let associtaedAssetsList: AssetDetails[] = [];
      const assetResponse = await api.getAssetsByid(id);
      dispatch(assetByIdDataObject(assetResponse));
      const associatedAssetsResponse = await api.getAssociatedAssets(id);
      let addressList = [];
      addressList.push(associatedAssetsResponse);
      dispatch(repairAddressData(addressList));
      associtaedAssets.push(associatedAssetsResponse);
      const queryParams = {
        searchText: "",
        limit: 1,
        skip: 0,
        customerType: "",
        orderBy: "",
        tenancyType: "",
        uprn: assetResponse?.uprn,
      };
      assetRegionValue = assetResponse?.region;
      const customerSearchApi = await api.getCustomerData(queryParams);
      const customerCallResponse = customerSearchApi?.models;
      if (
        customerCallResponse.length !== 0 &&
        customerCallResponse[0]?.id !== undefined &&
        customerCallResponse[0]?.id !== ""
      ) {
        setIsWorkOrderCustomerCard(true);
        customerResponse = await api.getCustomer(customerCallResponse[0]?.id);
        dispatch(getCustomerId(customerCallResponse[0]?.id));
        dispatch(saveCustomerId(customerCallResponse[0]?.id))
        customerID = customerCallResponse[0]?.id;
        let custResponse: CustomerResponseDetails =
          new CustomerResponseDetails();
        custResponse = customerResponse;
        const activeTenanciesSummaries =
          custResponse?.tenanciesSummaries.filter(
            (ts) =>
              ts.tenancySummary.statuses.find(
                (s) => s.type === "isTenancyActive"
              )?.value === true
          );
        if (activeTenanciesSummaries.length > 0) {
          setActiveTenancy(true);
          dispatch(isActiveTenancy(true));
          dispatch(userChosenAssociatedAssetsList(activeTenanciesSummaries));
        } else {
          dispatch(isActiveTenancy(false));
          setActiveTenancy(false);
        }
        setCustomerDetails(customerResponse);
        if (
          workOrders.identifiers?.customerIdentifier !== null &&
          workOrders.identifiers?.customerIdentifier !== undefined &&
          workOrders.identifiers?.customerIdentifier !== ""
        ) {
          setCustomerIdentifier(workOrders.identifiers?.customerIdentifier);
        } else {
          setCustomerIdentifier(customerCallResponse[0]?.id);
        }
        if (customerResponse?.addresses?.telecomsAddresses) {
          dispatch(
            saveCustomerphoneNumber(
              customerResponse?.addresses?.telecomsAddresses
            )
          );
        } else {
          dispatch(saveCustomerphoneNumber(null));
        }
        dispatch(selectedTenacyType(workOrders?.asset?.type?.assetTypeName));
      }

      const appCatalystRegistrationAreasValues =
        await api.GetAppConfigToggleValue(
          LandlordAppConfigValues.CatalystRegistrationAreas
        );
      const appCatalystRepairDisabledAreasValues =
        await api.GetAppConfigToggleValue(
          LandlordAppConfigValues.CatalystRepairDisabledAreas
        );

      const isCatalystDisabledArea =
        !isUndefinedNullOrEmpty(assetRegionValue) &&
        appCatalystRepairDisabledAreasValues.value
          .toLowerCase()
          .includes(assetRegionValue.toLowerCase());
      let isCatalystRegistrationArea =
        !isUndefinedNullOrEmpty(assetRegionValue) &&
        appCatalystRegistrationAreasValues.value
          .toLowerCase()
          .includes(assetRegionValue.toLowerCase());
      let isEnabledCatalystProperty =
        isCatalystDisabledArea && !isCatalystDisabledArea;

      const associatesdata = await api.GetPropertyAssociates(assetResponse?.id);
      let currentLandlord =
        associatesdata?.associates?.landLord?.organisation?.name;
      let previousLandLord =
        associatesdata?.associates?.previousLandLord?.organisation?.name;
      const appConfigLandlordValues = await api.GetAppConfigToggleValue(
        LandlordAppConfigValues.LandlordValues
      );

      const catalystLandlord =
        (currentLandlord?.length > 0 &&
          appConfigLandlordValues?.value.includes(currentLandlord)) ||
        (previousLandLord?.length > 0 &&
          appConfigLandlordValues?.value.includes(previousLandLord));

      const identifiedCatalystProperty =
        isCatalystRegistrationArea || catalystLandlord;

      setIsCataLyst(identifiedCatalystProperty);
      dispatch(isCatalystProperty(identifiedCatalystProperty));

      switch (assetRegionValue) {
        case "HUB1-REP":
          setCatalystBannerMessageA(t("CP126EnabledHub1a"));
          setCatalystBannerMessageB(t("CP126EnabledHubb"));
          break;
        case "HUB2-REP":
          setCatalystBannerMessageA(t("CP126EnabledHub2a"));
          setCatalystBannerMessageB(t("CP126EnabledHubb"));
          break;
        case "HUB3-REP":
          setCatalystBannerMessageA(t("CP126EnabledHub3a"));
          setCatalystBannerMessageB(t("CP126EnabledHubb"));
          break;
        case "HUB4-REP":
          setCatalystBannerMessageA(t("CP126EnabledHub4a"));
          setCatalystBannerMessageB(t("CP126EnabledHubb"));
          break;
        default:
          // For Non Catalyst Properties, isIdentifiedCatalystProperty will be false, message wont be visible
          // For Catalyst properties where landlord is catalyst but fron a diff asset region
          setCatalystBannerMessageA(t("CP125a"));
          setCatalystBannerMessageB(t("CP125b"));
          break;
      }

      if (isCatalystDisabledArea) {
        setCatalystBannerMessageA(t("CP125a"));
        setCatalystBannerMessageB(t("CP125b"));
      }
      if (
        assetResponse?.addresses?.postalAddresses[0].formattedAddress != null
      ) {
        dispatch(
          userChosenPropertyAddress(
            assetResponse?.addresses?.postalAddresses[0].formattedAddress
          )
        );
      }
      let associatedAssetData: any;
      setAssetValue(assetResponse?.assetType);
      dispatch(userChosenAssetValue(assetResponse?.assetType));
      if (assetResponse?.assetType == PremisesType.Estate) {
        associatedAssetData = associtaedAssets[0].filter(
          (x) => x.assetType === PremisesType.Estate
        );
        associtaedAssetsList.push(associatedAssetData);
      }
      if (assetResponse?.assetType == PremisesType.Block) {
        associatedAssetData = associtaedAssets[0].filter(
          (x) =>
            x.assetType === PremisesType.Block ||
            x.assetType === PremisesType.Estate
        );
        associtaedAssetsList.push(associatedAssetData);
      }
      if (assetResponse?.assetType == PremisesType.DividedBlock) {
        associatedAssetData = associtaedAssets[0].filter(
          (x) =>
            x.assetType === PremisesType.Block ||
            x.assetType === PremisesType.Estate ||
            x.assetType === PremisesType.DividedBlock
        );
        associtaedAssetsList.push(associatedAssetData);
      }
      if (assetResponse?.assetType == PremisesType.Property) {
        associatedAssetData = associtaedAssets[0];
        associtaedAssetsList.push(associatedAssetData);
      }
      dispatch(saveAssociatedAssests(associtaedAssetsList));
      setAssetUprn(assetResponse.uprn);

      dispatch(assetDataStatus(assetResponse?.assetStatus));
    } catch (e) {
      setIsLoading(false);
      console.error(e);
      props.history.push("/GenericError");
    }
  };
  const backButtonHandler = () => {
    if (finishJourney) {
      props.history.push("/LandingView");
    } else history.goBack();
  };
  const dropDownFunction = () => {
    if (dropDown === "none") setDropDown("block");
    else setDropDown("none");
  };
  const callPageHandler = () => {
    //dispatch(getCustomerId(workOrders?.identifiers?.customerIdentifier));

    if (!isP360Maintainence) {
      dispatch(woRedirectFromEmailStatus(false));
      dispatch(selectedTenacyType(workOrders?.asset?.type?.assetTypeName));
      if (isNullOrUndefined(customerDetails?.addresses?.telecomsAddresses)) {
        dispatch(saveCustomerphoneNumber(null));
      } else {
        dispatch(
          saveCustomerphoneNumber(customerDetails?.addresses?.telecomsAddresses)
        );
      }
      props.history.push("/RepairAsset");
    }
  };

  const selectAppointmentHandler = () => {
    if (!isP360Maintainence) {
      setIsSelectAppointmentChosen(true);
      if (!woHadDemobilizedContractorSite) {
        const historyData = CreateHistoryData();
        dispatch(repairHistoryData(historyData));
        props.history.push("/AppointmentScheduler");
      }
    }
  };

  const CreateHistoryData = () => {
    return {
      repairHistoryAppointment: props.Appointment,
      repairHistoryPriority: props.Priority,
      repairHistoryContractor: props.Contractor,
      repairHistoryDescription: props.Description,
      repairHistoryWorkOrderID: workOrders.identifiers?.worksOrderIdentifier,
      repairHistoryStatus: props.status,
      repairHistoryRepairReference: props.repairReference,
      repairHistoryArea: props.area,
      repairHistoryFullDescription: props.fullDescription,
    };
  };

  const recallRepairHandler = async () => {
    try {
      if (draftRepair) {
        setIsLoading(true);
        dispatch(userChosenIsRecall(true));
        const workOrderRepair = new CurrentRepair();
        workOrderRepair.repairReason = draftRepair.repairReason;
        workOrderRepair.description = draftRepair.repairReason;
        workOrderRepair.additionalInformation = draftRepair.description;
        workOrderRepair.premisesId = draftRepair.assetIdentifier;
        workOrderRepair.premisesTypeId = draftRepair.premisesTypeId;
        workOrderRepair.customerIdentifier = draftRepair.customerIdentifier;
        workOrderRepair.isEmergency = draftRepair.isEmergency;
        workOrderRepair.premisesUniqueReference =
          draftRepair.premisesUniqueReference;
        workOrderRepair.premisesAddress = draftRepair.premisesAddress;
        workOrderRepair.sorCode = draftRepair.sorCode;
        workOrderRepair.customerName = new CustomerName({
          title: draftRepair?.customerName?.title,
          givenName: draftRepair?.customerName?.givenName,
          surname: draftRepair?.customerName?.surname,
        });
        workOrderRepair.reportingMethodId = draftRepair.reportingMethodId;
        workOrderRepair.reportingMethod = draftRepair.reportingMethod;

        workOrderRepair.notifyAll = false;
        workOrderRepair.eTag = draftRepair.eTag;
        workOrderRepair.eligibilityOutcomes = [];
        workOrderRepair.assetIdentifier = draftRepair.assetIdentifier;
        workOrderRepair.repairLocationId = draftRepair.repairLocationId;
        workOrderRepair.repairCause = "General Wear and Tear";

        workOrderRepair.repairCauseId = draftRepair.repairCauseId;

        workOrderRepair.isRecall = true;

        workOrderRepair.id = draftRepair.id;

        const _worksOrderRequest = new WorksOrderRequest(workOrderRepair);
        const woRequestParameters =
          _worksOrderRequest.createWorkOrderParameter(workOrderRepair);
        let apiResponse: any;
        try {
          apiResponse = await api.createWorkOrderRepair({
            ...woRequestParameters,
          });
        } catch (e) {
          console.error(e);
          props.history.push("/GenericError");
        }

        if (apiResponse?.location.length > 0) {
          const workorderLocation = apiResponse?.location.split("/");
          workOrders.id = workorderLocation[4];
          workOrders.worksOrderIdentifier = workorderLocation[4];

          draftRepair.id = workorderLocation[4];
          draftRepair.worksOrderIdentifier = workorderLocation[4];
          draftRepair.isRecall = true;

          const reportContactDetails = new ReportContactDetails();
          reportContactDetails.contactNumber =
            workOrders?.reporter?.addresses?.telecomsAddresses?.[0]?.number;

          reportContactDetails.reporterToBeContacted = false;

          const contactPersonName = new CustomerName({
            title: null,
            givenName: draftRepair?.customerName?.givenName,
            surname: null,
          });

          reportContactDetails.name = contactPersonName;
          draftRepair.reporterContactDetails = reportContactDetails;

          draftRepair.description = draftRepair.repairReason;

          draftRepair.reportedBy = "Customer";
          draftRepair.reportedById = 1;

          //---

          const _worksOrderRequest = new WorksOrderRequest(draftRepair);
          const woRequestParameters =
            _worksOrderRequest.updateWorkOrderParameter(draftRepair);

          woRequestParameters.originalWorkOrderIdentifier =
            draftRepair.originalWorkOrderIdentifier;
          try {
            await api.updateWorkOrderRepair(
              woRequestParameters,
              draftRepair.id,
              "eTag"
            );
          } catch (e) {
            console.error(e);
            props.history.push("/GenericError");
          }

          // get new WO data
          let workOrderDataResponse: any;
          try {
            workOrderDataResponse = await api.getWorkOrder(draftRepair.id);
          } catch (e) {
            console.error(e);
            props.history.push("/GenericError");
          }

          dispatch(workOrderData(workOrderDataResponse));
          dispatch(workOrderRepairObject(draftRepair));

          //fetch all data and redirect to repairDetails page
          //set all values for detail page

          //get eligibility
          const assetResponse = await api.getAssetsByid(draftRepair.premisesId);
          if (assetResponse) {
            dispatch(assetByIdDataObject(assetResponse));
          }
          dispatch(
            userChosenSupplementaryQuestions(draftRepair.isSupplementary)
          );
          dispatch(userChosenVandalismQuestion(draftRepair.isVandalism));
          dispatch(userChosenCovidQuestion(draftRepair.isCovidSelfIsolated));

          dispatch(
            userChosenDuplicateQuestion(draftRepair.isPotentialDuplicate)
          );
          dispatch(
            userChosenSupplementaryQuestions(draftRepair.isSupplementary)
          );
          dispatch(
            userChosenVulnerabilityQuestion(
              draftRepair.isVulnerable ? "Yes" : "No"
            )
          );
          dispatch(
            userChosenVulnerabilityAdditionalInformation(
              draftRepair.VulnerableResponseText
            )
          );
          dispatch(userChosenSOR(draftRepair.sorCode));
          dispatch(userChosenRepairLocation(draftRepair.repairLocation));
          dispatch(userChosenRepairLocationId(draftRepair.repairLocationId));

          dispatch(userChosenRelationDetails(draftRepair.reportedBy));
          dispatch(
            userChosenAdditionalDescription(draftRepair.additionalInformation)
          );

          customerResponse = customerDetails;
          dispatch(saveCustomerDetails(customerResponse));
          dispatch(
            saveCustomerphoneNumber(
              customerResponse?.addresses?.telecomsAddresses
            )
          );
          //dispatch(userChosenIsReporter(draftRepair?.reporterToBeContacted));
          dispatch(userChosenIsReporter(false));
          if (draftRepair?.reporterContactDetails != null) {
            const name =
              draftRepair.reporterContactDetails?.name?.givenName?.trim();
            dispatch(
              userChosenContactPhone(
                draftRepair.reporterContactDetails.contactNumber
              )
            );
            if (!userChosenContactNameVal) {
              dispatch(userChosenContactName(""));
            } else {
              dispatch(userChosenContactName(name));
            }
            dispatch(userChosenRelationDetails(draftRepair.reportedBy));
            dispatch(
              userChosenContactDetails(
                draftRepair.reporterContactDetails.contactNumber
              )
            );
          } else {
            dispatch(userChosenRelationDetails("Customer"));
            dispatch(userChosenContactPhone(""));
            dispatch(
              userChosenContactDetails(
                customerResponse?.addresses?.telecomsAddresses?.[0]?.number
              )
            );
          }

          dispatch(userChosenHighLevelDescription(draftRepair.repairReason));
          setIsLoading(false);
          dispatch(
            userChosenNotifyAll(
              draftRepair?.notifyAll === true
                ? "Yes"
                : draftRepair?.notifyAll === false
                ? "No"
                : null
            )
          );
          props.history.push("/ConfirmationDetails");
        }
      }
    } catch (e) {
      console.error(e);
      const timerId = setTimeout(() => {
        props.history.push("/GenericError");
      }, 1000);
    }
  };

  const resumeRepairHandler = async (
    draftRepair: any,
    isEmailRedirect: boolean = false
  ) => {
    if (!isP360Maintainence) {
      dispatch(resumeDraftRepair(true));
      dispatch(isRepairMediaResume(true));
      let assetResponse = null;
      customerResponse = customerDetails;
      dispatch(saveCustomerDetails(customerResponse));
      dispatch(
        saveCustomerphoneNumber(customerResponse?.addresses?.telecomsAddresses)
      );
      dispatch(userChosenIsReporter(draftRepair?.reporterToBeContacted));
      if (draftRepair?.reporterContactDetails != null) {
        const name =
          draftRepair.reporterContactDetails?.name?.givenName?.trim();
        dispatch(
          userChosenContactPhone(
            draftRepair.reporterContactDetails.contactNumber
          )
        );
        dispatch(userChosenContactName(name));
        dispatch(userChosenRelationDetails(draftRepair.reportedBy));
        dispatch(
          userChosenContactDetails(
            draftRepair.reporterContactDetails.contactNumber
          )
        );
        //draftRepair.reporterContactDetails.name = null;
      } else {
        dispatch(userChosenRelationDetails("Customer"));
        dispatch(userChosenContactPhone(""));
        dispatch(
          userChosenContactDetails(
            customerResponse?.addresses?.telecomsAddresses?.[0]?.number
          )
        );
      }
      /*** This fetches the Updated Seasonal Priority for mapped Repair reasons from Drools ***/

      dispatch(selectedRepairReasonPriorityOverride(null));
      const priorityDeterminationResponse =
        await api.repairPriorityDetermination(draftRepair?.description);

      const repairReasonPriorityIsUpdated =
        priorityDeterminationResponse?.priority != null &&
        priorityDeterminationResponse?.priority !== draftRepair.priority;
      draftRepair.priority = repairReasonPriorityIsUpdated
        ? priorityDeterminationResponse.priority
        : draftRepair.priority;
      draftRepair.isEmergency =
        draftRepair.priority == "Emergency" ||
        draftRepair.priority == "Emergency (OOH)"
          ? true
          : false;
      dispatch(selectedRepairReasonPriorityOverride(draftRepair.priority));
      dispatch(
        resumeRepairReasonPriorityUpdatedToEmergency(
          repairReasonPriorityIsUpdated && draftRepair.isEmergency
        )
      );

      dispatch(workOrderRepairObject(draftRepair));

      const {
        id,
        premisesId,
        repairLocationId,
        repairLocation,
        repairReason,
        isSupplementary,
        isEmergency,
        isPotentialDuplicate,
        additionalInformation,
        isVandalism,
        repairCauseId,
        crimeReferenceNumber,
        isVulnerable,
        VulnerableResponseText,
        isCovidSelfIsolated,
        reporterContactDetails,
        premisesUniqueReference,
        originalWorkOrderSorCode,
        priority,
        area,
      } = draftRepair;
      // const area = props?.area != undefined ? props?.area : "";
      try {
        assetResponse = await api.getAssetsByid(draftRepair.premisesId);
        dispatch(assetByIdDataObject(assetResponse));
      } catch (e) {
        console.error(e);
        props.history.push("/GenericError");
      }
      dispatch(selectedTenacyType(area));

      const premiseType =
        area === "Block" || area === "Estate" || area === "Divided block"
          ? "Communal"
          : area;
      let responsedata: any;
      try {
        responsedata = await api.searchRepairReasons(
          repairReason,
          premiseType,
          identifiedCatalystProperty
        );
      } catch (e) {
        console.error(e);
        props.history.push("/GenericError");
      }
      const selectedReason = responsedata?.filter(
        (reason: any) => reason.description === repairReason
      );

      // Calling tenancy api to get tenency group when user resume
      if (draftRepair?.premisesId && customerID) {
        const responseTenancyGroup = await api.getTenanciesGroup(
          customerID,
          premisesId
        );
        dispatch(selectedTenancyGroup(responseTenancyGroup[0]?.tenancyGroup || ""));
      }

      // Dispatching reason for AR1 role to popup Heating hot water dropdown
      dispatch(fetchRepairReasonsSelect(responsedata));

      if (draftRepair?.premisesId && customerID) {
        const responseTenancyGroup = await api.getTenanciesGroup(
          customerID,
          draftRepair?.premisesId
        );
        dispatch(selectedTenancyGroup(responseTenancyGroup[0]?.tenancyGroup || ""));
      }

      var maintsResp =
        assetResponse?.maintenanceResponsibilities?.find(
          (resp: any) => resp.type === "MAINTSRESP"
        )?.value ?? "";
      var repairResp =
        assetResponse?.maintenanceResponsibilities?.find(
          (resp: any) => resp.type === selectedReason[0]?.element
        )?.value ?? "";
      dispatch(getMaintResp(maintsResp));
      dispatch(getRepairResp(repairResp));

      if (
        selectedReason[0]?.additionalData &&
        selectedReason[0]?.additionalData.length &&
        selectedReason[0]?.additionalData[0]?.description != ""
      ) {
        const resumeData = {
          resumeHLRepair: selectedReason[0]?.description,
          resumeHLSubQuestion:
            selectedReason[0]?.additionalData[0]?.description,
          resumeSelectedProperty: area,
          resumePriority: priority,
        };
        dispatch(userResumeData(resumeData));
      } else {
        const resumeData = {
          resumeHLRepair: "",
          resumeHLSubQuestion: "",
          resumeSelectedProperty: area,
          resumePriority: priority,
        };
        dispatch(userResumeData(resumeData));
      }

      dispatch(userChosenSOR(originalWorkOrderSorCode));
      if (draftRepair.isPotentialDuplicate) {
        dispatch(
          userChosenDuplicateQuestion("I don't think this is a duplicate")
        );
      }
      repairLocation &&
        dispatch(userChosenRepairLocation(repairLocation)) &&
        dispatch(userChosenRepairLocationId(repairLocationId));
      repairReason && dispatch(userChosenHighLevelDescription(repairReason));
      isSupplementary === true
        ? dispatch(userChosenSupplementaryQuestions("Yes"))
        : isSupplementary === false
        ? dispatch(userChosenSupplementaryQuestions("No"))
        : dispatch(userChosenSupplementaryQuestions(""));
      isEmergency &&
        dispatch(
          userChosenEmergencyQuestion(t("Repair_Emergency_Radio1_Text"))
        );
      additionalInformation &&
        dispatch(userChosenAdditionalDescription(t(additionalInformation))) &&
        dispatch(
          userChosenAdditionalDescriptionCOUNT(additionalInformation.length)
        );
      isVandalism
        ? dispatch(userChosenVandalismQuestion("Yes")) &&
          dispatch(
            userChosenVandalismAdditionalInformation(crimeReferenceNumber)
          )
        : dispatch(userChosenVandalismQuestion("No"));
      isVulnerable
        ? dispatch(userChosenVulnerabilityQuestion("Yes")) &&
          dispatch(
            userChosenVulnerabilityAdditionalInformation(VulnerableResponseText)
          )
        : dispatch(userChosenVulnerabilityQuestion("No"));
      isCovidSelfIsolated === true
        ? dispatch(userChosenCovidQuestion("Yes"))
        : isCovidSelfIsolated === false
        ? dispatch(userChosenCovidQuestion("No"))
        : dispatch(userChosenCovidQuestion(""));
      const resumeDraftDataCache = {
        repairLocation,
        repairLocationId,
        repairReason,
        repairSORCode: draftRepair?.sorCode,
        supplementaryQuestions:
          isSupplementary === true
            ? "Yes"
            : isSupplementary === false
            ? "No"
            : "",
        emergencyQuestion: isEmergency && t("Repair_Emergency_Radio1_Text"),
        additionalInformation,
        vandalismQuestion: isVandalism ? "Yes" : "No",
        crimeReferenceNumber: isVandalism ? crimeReferenceNumber : "",
        vulnerabilityQuestion: isVulnerable ? "Yes" : "No",

        vulnerableResponseText: isVulnerable ? VulnerableResponseText : "",
        covidQuestion:
          isCovidSelfIsolated === true
            ? "Yes"
            : isCovidSelfIsolated === false
            ? "No"
            : "",
        reporterContactDetails,
      };
      dispatch(userResumeDraftDataCache(resumeDraftDataCache));
      dispatch(
        userChosenNotifyAll(
          draftRepair?.notifyAll === true
            ? "Yes"
            : draftRepair?.notifyAll === false
            ? "No"
            : null
        )
      );
      // dispatching reason for AR1 role to popup Heating hot water dropdown
      dispatch(fetchRepairReasonsSelect(responsedata));

      // Adding redirect logic when resuming through email link
      if (
        (isEmailRedirect &&
          duplicateOverridePermissions &&
          choosePriorityPermission &&
          draftRepair?.isPotentialDuplicate &&
          draftRepair?.isVulnerable) ||
        (isEmailRedirect &&
          choosePriorityPermission &&
          noTriagePermission &&
          draftRepair?.isVulnerable &&
          draftRepair?.sorCode == "NA") ||
        (isEmailRedirect &&
          duplicateOverridePermissions &&
          noTriagePermission &&
          draftRepair?.isPotentialDuplicate &&
          draftRepair?.sorCode == "NA") ||
        (isEmailRedirect &&
          duplicateOverridePermissions &&
          noTriagePermission &&
          choosePriorityPermission &&
          draftRepair?.isPotentialDuplicate &&
          draftRepair?.isVulnerable &&
          draftRepair?.sorCode == "NA") ||
        (isEmailRedirect && noTriagePermission && draftRepair?.sorCode == "NA")
      ) {
        props.history.push("/RepairLocation");
      } else if (
        isEmailRedirect &&
        duplicateOverridePermissions &&
        draftRepair?.isPotentialDuplicate
      ) {
        dispatch(woRedirectFromEmailStatus(true));
        props.history.push("/DuplicateRepair");
      } else if (
        isEmailRedirect &&
        choosePriorityPermission &&
        draftRepair?.isVulnerable
      ) {
        props.history.push("/ConfirmationDetails");
      } else if (!isEmailRedirect) {
        // Handling existing logic when it's not coming from email redirect
        if (
          !repairLocation ||
          !repairReason ||
          draftRepair.sorCode == "NA" ||
          isNullOrUndefined(draftRepair.sorCode)
        ) {
          props.history.push("/RepairLocation");
        } else if (
          repairReasonPriorityIsUpdated === true &&
          draftRepair.isEmergency
        ) {
          props.history.push("/RepairEmergency");
        } else if (!additionalInformation) {
          if (isSupplementary === null) {
            props.history.push("/SupplementaryQuestion");
          } else if (isEmergency !== true && isEmergency !== false) {
            props.history.push("/RepairEmergency");
          } else if (isPotentialDuplicate === true) {
            props.history.push("/RepairAdditionalDetails");
          } else {
            props.history.push("/DuplicateRepair");
          }
        } else if (
          additionalInformation &&
          isRepairMedia == "true" &&
          draftRepair.documents.documentIdentifiers.length == 0
        ) {
          props.history.push("/RepairUploadMedia");
        } else if (
          additionalInformation &&
          isVandalism == null &&
          isVulnerable == null &&
          isCovidSelfIsolated == null &&
          reporterContactDetails == null &&
          isRepairMedia == "true"
        ) {
          props.history.push("/RepairUploadMedia");
        } else if (
          isVandalism === null &&
          assetDetails?.statuses.isInDefects === true &&
          vandalismConfigValue === true
        ) {
          props.history.push("/VandalismQuestion");
        } else if (isVulnerable === null) {
          props.history.push("/VulnerabilityQuestion");
        } else if (isVulnerable === true && VulnerableResponseText === null) {
          props.history.push("/VulnerabilityQuestion");
        } else if (
          area === "Property" &&
          isCovidSelfIsolated === null &&
          covidConfigValue
        ) {
          props.history.push("/CovidQuestion");
        } else if (reporterContactDetails != null) {
          if (
            draftRepair.reporterContactDetails?.name?.givenName?.trim()
              ?.length === 0 ||
            draftRepair.reporterContactDetails?.contactNumber?.trim()
              ?.length === 0 ||
            draftRepair.reporterContactDetails?.name === null
          ) {
            if (isCovidSelfIsolated === null && covidConfigValue) {
              draftRepair.reporterContactDetails.name = null;
              props.history.push("/CovidQuestion");
            } else {
              draftRepair.reporterContactDetails.name = null;
              props.history.push("/CustomerDetails");
            }
          } else if (area !== "Property" && draftRepair.notifyAll === null) {
            props.history.push("/BlockCommunication");
          } else {
            draftRepair.reporterContactDetails.name = null;
            props.history.push("/ConfirmationDetails");
          }
        } else if (draftRepair.reportedBy.trim().length <= 0) {
          props.history.push("/CustomerDetails");
        } else if (area !== "Property" && draftRepair.notifyAll === null) {
          props.history.push("/BlockCommunication");
        } else {
          if (draftRepair.reporterContactDetails != null) {
            const name =
              draftRepair.reporterContactDetails?.name?.givenName?.trim();
            dispatch(
              userChosenContactPhone(
                draftRepair.reporterContactDetails.contactNumber
              )
            );
            dispatch(userChosenContactName(name));
            dispatch(userChosenRelationDetails(draftRepair.reportedBy));
            dispatch(
              userChosenContactDetails(
                "I would like to provide number for this repair."
              )
            );
          } else {
            dispatch(userChosenRelationDetails("Customer"));
            dispatch(
              userChosenContactPhone(
                customerResponse?.addresses?.telecomsAddresses?.[0]?.number
              )
            );
            dispatch(
              userChosenContactDetails(
                customerResponse?.addresses?.telecomsAddresses?.[0]?.number
              )
            );
          }
          props.history.push("/ConfirmationDetails");
        }
      }
    }
  };

  const getCancelRepairEligibility = async (
    workOrderResponse: CurrentRepair,
    assetTypeName: string | null | undefined
  ) => {
    const _customerId = localStorage.getItem("agent") || "";
    //get cancel repair eligibility
    const eligibleWorkProgramme = ["RESP", "RECALL", "GASRESP", "GASRECAL"];

    const checkAppointment: boolean =
      eligibleWorkProgramme.indexOf(
        workOrderResponse.workProgramme?.toUpperCase()
      ) > -1;
    setIsAppointment(checkAppointment);

    let assetType = workOrderResponse.area || assetTypeName || "";
    let assetTypeNameToUpdate = assetType;
    if (
      assetTypeNameToUpdate &&
      assetTypeNameToUpdate != "" &&
      assetTypeNameToUpdate.toLowerCase() !== "property"
    ) {
      assetTypeNameToUpdate = "Communal";
    }

    let eligibilityResponse: any;
    try {
      eligibilityResponse = await api.getCancelRepairEligibility(
        (workOrderResponse.reportingMethod = "ColleagueSelfService"),
        workOrderResponse.status,
        workOrderResponse.isDraft,
        workOrderResponse.isEmergency,
        workOrderResponse.isOutOfHours,
        workOrderResponse.isRecall != undefined
          ? workOrderResponse.isRecall
          : false,
        workOrderResponse.isEMA,
        workOrderResponse.isPotentialDuplicate,
        workOrderResponse.contractor,
        workOrderResponse.repairLocation,
        workOrderResponse.trade,
        workOrderResponse.sorCode,
        moment(new Date(workOrderResponse.raisedDate)).format("yyyy-MM-DD"),
        assetTypeNameToUpdate,
        workOrderResponse.appointmentStartDate,
        _customerId,
        workOrderResponse.customerIdentifier
      );
    } catch (e) {
      console.error(e);
      props.history.push("/GenericError");
    }
    setArea(assetType);
    setContractor(workOrderResponse.contractor);
    if (eligibilityResponse && eligibilityResponse.isEligible) {
      setIsCancelButton(true);
      if (eligibilityResponse.errorCode === "") {
        setErrorCode("SSR036");
      } else {
        setErrorCode(eligibilityResponse.errorCode);
      }
    }
  };
  return (
    <div className="border-t border-grey pb-12 bg-brandmark-image bg-no-repeat bg-right-60% bg-50% md:bg-35% lg:bg-20% bg-fixed">
      <div
        id="backmodal-div1"
        className="container mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0 global-txt"
      >
        <div id="backmodal-p" className="text-teal text-xl mt-4 mb-4 sm-pl-1">
          <a
            data-testid="workOrderBackBtn"
            className="back-btn"
            onClick={backButtonHandler}
            onKeyDown={backButtonHandler}
          >
            <i className="fas fa-angle-left mr-2 "></i>
            <span>{t("back")}</span>
          </a>
        </div>
      </div>
      {!isLoading ? (
        <div className="container mx-auto">
          <div className="w-full global-txt px-2 ">
            <div className="flex flex-row d-flex bd-highlight">
              <div className="bd-highlight  ">
                <h1 className="mb-4 sm:mt-1">
                  {workOrders?.identifiers?.jobNumber ? (
                    <> {workOrders?.identifiers?.jobNumber}</>
                  ) : (
                    jobNumber
                  )}{" "}
                </h1>

                <p className="mt-2 global-txt">{t("CP076_Text")}</p>
              </div>

              <div className="menu-nav ms-auto bd-highlight ">
                <div className="dropdown-container-h btn-group">
                  <div
                    data-testid="dotsmenu"
                    className="btn btn-sm show three-dots-ast dwn_rt"
                    data-bs-toggle="dropdown"
                    onClick={dropDownFunction}
                    onKeyDown={dropDownFunction}
                  ></div>

                  <div
                    className=" dropdown-ast px-2 dropdown-menu show mt-45"
                    style={{ display: dropDown }}
                  >
                    {(activeTenancy && assetValue === "Property") ||
                    assetValue === "Divided Block" ||
                    assetValue === "Block" ||
                    assetValue === "Estate" ? (
                      <a
                        data-testid="callpage"
                        className="text-base no-underline"
                        onClick={callPageHandler}
                        onKeyDown={callPageHandler}
                      >
                        <div
                          className={
                            "py-2 cursor-pointer text-black text-base no-underline dropdown-item " +
                            (isP360Maintainence
                              ? "text-opacity-25 pe-none"
                              : "")
                          }
                        >
                          {assetValue === "Property"
                            ? t("Create_repair")
                            : t("raise_comm")}
                        </div>
                      </a>
                    ) : null}
                    {(workOrders?.statuses?.status === "In Diagnosis" &&
                      !isUnderReviewTriage) ||
                    (isUnderReviewTriage && reviewTriageRepairPermission) ? (
                      <a
                        data-testid="resumeRepair"
                        onClick={(e) => resumeRepairHandler(draftRepair)}
                        onKeyDown={(e) => resumeRepairHandler(draftRepair)}
                        className="text-black text-base no-underline global-txt"
                      >
                        <div
                          className={
                            "py-2 cursor-pointer text-black text-base no-underline dropdown-item " +
                            (isP360Maintainence
                              ? "text-opacity-25 pe-none"
                              : "")
                          }
                        >
                          {" "}
                          {t("Resume_repair")}
                        </div>
                      </a>
                    ) : null}
                    {isAppointmentRole &&
                    isAppointment &&
                    isContractorIntegerated &&
                    workOrders?.statuses?.status === "Unappointed" &&
                    draftRepair?.priority === "Next Available" ? (
                      <a
                        data-testid="selectAppointment"
                        onClick={selectAppointmentHandler}
                        onKeyDown={selectAppointmentHandler}
                        className="text-black text-base no-underline"
                      >
                        <div
                          className={
                            "py-2 cursor-pointer text-black text-base no-underline dropdown-item " +
                            (isP360Maintainence
                              ? "text-opacity-25 pe-none"
                              : "")
                          }
                        >
                          {" "}
                          {t("CP070")}
                        </div>
                      </a>
                    ) : null}

                    {isRecallEligible ? (
                      <a
                        data-testid="recallRepair"
                        onClick={recallRepairHandler}
                        onKeyDown={recallRepairHandler}
                        className="text-black text-base no-underline"
                      >
                        <div className="py-2 cursor-pointer text-black text-base no-underline dropdown-item">
                          {" "}
                          {t("CP107")}
                        </div>
                      </a>
                    ) : null}

                    {isCancelButton == true &&
                    workOrders?.job?.repairType == WOTypes.repair ? (
                      <div
                        className="global-txt"
                        x-ref="container"
                        id="hisRepDet-con"
                      >
                        <div
                          id="hisRepDet-main-btn"
                          className="px-0 md:flex md:justify-between "
                        >
                          <div>
                            <CancelModelRepair
                              id="repreason-exitmodal"
                              workOrderId={
                                workOrders?.identifiers?.worksOrderIdentifier
                              }
                              area={area}
                              errorCode={errorCode}
                              contractor={contractor}
                              props={props}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {isCancelButton &&
                    isCreateSurvey &&
                    workOrders?.job?.repairType == WOTypes.survey ? (
                      <div
                        className="global-txt"
                        x-ref="container"
                        id="hisRepDet-con"
                      >
                        <div
                          id="hisRepDet-main-btn"
                          className="px-0 md:flex md:justify-between "
                        >
                          <div>
                            <CancelModelSurvey
                              id="repreason-exitmodal"
                              workOrderId={
                                workOrders?.identifiers?.worksOrderIdentifier
                              }
                              area={area}
                              errorCode={errorCode}
                              contractor={contractor}
                              props={props}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            {identifiedCatalystProperty ? (
              <div className="warning-pre-line alert p-0">
                <div className="w-full px-2 py-2 bg-purple-20 flex items-center">
                  <div className="px-4 pr-8">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      fill="currentColor"
                      className="text-purple-100 w-6 h-6"
                    >
                      <path d="M480 179.6C498.6 188.4 512 212.1 512 240C512 267.9 498.6 291.6 480 300.4V448C480 460.9 472.2 472.6 460.2 477.6C448.3 482.5 434.5 479.8 425.4 470.6L381.7 426.1C333.7 378.1 268.6 352 200.7 352H192V480C192 497.7 177.7 512 160 512H96C78.33 512 64 497.7 64 480V352C28.65 352 0 323.3 0 288V192C0 156.7 28.65 128 64 128H200.7C268.6 128 333.7 101 381.7 53.02L425.4 9.373C434.5 .2215 448.3-2.516 460.2 2.437C472.2 7.39 480 19.06 480 32V179.6zM200.7 192H192V288H200.7C280.5 288 357.2 317.8 416 371.3V108.7C357.2 162.2 280.5 192 200.7 192V192z"></path>
                    </svg>
                  </div>
                  <div className="flex items-center">
                    <div className="w-full text-left my-2 font-AvantGardeGothic-Md">
                      <div>{catalystBannerMessageA}</div>
                      <br />
                      <div>{catalystBannerMessageB}</div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {workOrders?.identifiers?.contractorSiteIdentifier ===
              OnHoldContractor &&
              !workOrders?.identifiers?.jobNumber && (
                <div className="warning-pre-line alert p-0">
                  <div className="w-full px-2 py-2 bg-purple-20 flex items-center">
                    <div className="px-4 pr-8">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        fill="currentColor"
                        className="text-purple-100 w-6 h-6"
                      >
                        <path d="M480 179.6C498.6 188.4 512 212.1 512 240C512 267.9 498.6 291.6 480 300.4V448C480 460.9 472.2 472.6 460.2 477.6C448.3 482.5 434.5 479.8 425.4 470.6L381.7 426.1C333.7 378.1 268.6 352 200.7 352H192V480C192 497.7 177.7 512 160 512H96C78.33 512 64 497.7 64 480V352C28.65 352 0 323.3 0 288V192C0 156.7 28.65 128 64 128H200.7C268.6 128 333.7 101 381.7 53.02L425.4 9.373C434.5 .2215 448.3-2.516 460.2 2.437C472.2 7.39 480 19.06 480 32V179.6zM200.7 192H192V288H200.7C280.5 288 357.2 317.8 416 371.3V108.7C357.2 162.2 280.5 192 200.7 192V192z"></path>
                      </svg>
                    </div>
                    <div className="flex items-center">
                      <div className="w-full text-left my-2 font-AvantGardeGothic-Md">
                        <div>{t("responsive_repair")}</div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {isSelectAppointmentChosen && woHadDemobilizedContractorSite && (
              <div className="warning-pre-line alert p-0">
                <div className="w-full px-2 py-2 bg-purple-20 flex items-center">
                  <div className="px-4 pr-8">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      fill="currentColor"
                      className="text-purple-100 w-6 h-6"
                    >
                      <path d="M480 179.6C498.6 188.4 512 212.1 512 240C512 267.9 498.6 291.6 480 300.4V448C480 460.9 472.2 472.6 460.2 477.6C448.3 482.5 434.5 479.8 425.4 470.6L381.7 426.1C333.7 378.1 268.6 352 200.7 352H192V480C192 497.7 177.7 512 160 512H96C78.33 512 64 497.7 64 480V352C28.65 352 0 323.3 0 288V192C0 156.7 28.65 128 64 128H200.7C268.6 128 333.7 101 381.7 53.02L425.4 9.373C434.5 .2215 448.3-2.516 460.2 2.437C472.2 7.39 480 19.06 480 32V179.6zM200.7 192H192V288H200.7C280.5 288 357.2 317.8 416 371.3V108.7C357.2 162.2 280.5 192 200.7 192V192z"></path>
                    </svg>
                  </div>
                  <div className="flex items-center">
                    <div className="w-full text-left my-2 font-AvantGardeGothic-Md">
                      <div>
                        {t("please_contact")} {workOrders.contractor.name}{" "}
                        {t("on_phone")} {workOrders.contractor.phoneNumber}{" "}
                        {t("book_appointment")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {isCautionBanner ? (
              <div className="pb-3">
                <CautionAlert {...props} />
              </div>
            ) : null}
            {
              <div className="pb-3">
                <AdditionalNeedAlert {...props} />
              </div>
            }
            {isPropertyBanner && propertyCheck ? (
              <div className="pb-3">
                <PropertyAlert {...props} />
              </div>
            ) : null}

            {isWorkCard && isWorkOrderLoaded ? (
              <div className="grid grid-cols-1 gap-4 md:gap-8 mt-2">
                <WorkOrderDetails
                  id={workOrders?.identifiers?.worksOrderIdentifier}
                  status={status}
                  {...props}
                />
              </div>
            ) : null}

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
              {isWorkCard ? (
                <div className="relative pb-16 border border-grey bg-white shadow-lg rounded-lg cust-card">
                  <div>
                    <WorkOrderChronologySummary {...props} />
                  </div>
                </div>
              ) : null}

              {isRepairFullyCreated ? (
                <div className="mt-card">
                  <NotesCard
                    workOrderSerialNumber={woSerialNumber}
                    workOrderSequenceNumber={woSequenceNumber}
                    {...props}
                  />
                </div>
              ) : null}

              {isAssetCard && isAssetId ? (
                <div className="mt-card">
                  <AssetCard {...props} />
                </div>
              ) : null}

              <div className="mt-card">
                {isCustomerCard &&
                isWorkOrderCustomerCard &&
                (workOrders?.asset?.type?.assetTypeName === "Property" ||
                  assetValue === "Property") ? (
                  <WorkOrderCustomerCard
                    customerId={customerIdentifier}
                    propertyId={workOrders.identifiers?.asset?.assetIdentifier}
                    uprn={assetUprn}
                    workOrderId={workOrders.identifiers?.worksOrderIdentifier}
                    assetType={
                      workOrders.asset?.type?.assetTypeName
                        ? workOrders.asset?.type?.assetTypeName
                        : assetValue
                    }
                    {...props}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="div-loader">
          <div className="SpinnerWrapper">
            <LoadingSpinner />
          </div>
        </div>
      )}
    </div>
  );
};

export default WorkOrder360View;
